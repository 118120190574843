@import "./components/AddCredit/style";

.Billing {
  .ico {
    max-width: to-rem(23px);
  }

  .brands {
    img {
      max-width: to-rem(47px);
    }
  }

  .AccountBalance {
    .flex {
      align-items: center;
      margin-bottom: to-rem(30px);

      .button {
        min-width: to-rem(280px);
        font-weight: 500;
        font-size: to-rem(16px);
        letter-spacing: 0.5px;
      }
    }

    h3 {
      font-weight: 300;
      font-size: to-rem(27px);
      color: #444444;
      margin-top: 0;
      margin-bottom: to-rem(15px);
    }
    .price {
      font-weight: 700;
      font-size: to-rem(27px);
      color: #0069FF;
      margin: 0;
    }
  }

  .list-table {
    border: none;

    &__heading {
      background: transparent;
      border-bottom: none;
      padding: to-rem(10px) to-rem(20px);
      justify-content: space-between;
      color: #444444;
      font-size: to-rem(12px);
      text-transform: uppercase;

      & > div {
        flex: none;
        flex-grow: 1;
        margin: 0;
        text-align: left;

        &:nth-child(1) {
          max-width: to-rem(130px);
        }

        &:nth-child(2) {
          max-width: to-rem(150px);
        }

        &:nth-child(3) {
          max-width: to-rem(220px);
        }

        &:nth-child(4) {
          max-width: to-rem(150px);
        }

        &:nth-child(5) {
          max-width: to-rem(150px);
        }

        &:nth-child(6) {
          max-width: to-rem(110px);
        }

        &:nth-child(7) {
          max-width: to-rem(120px);
          justify-content: flex-end;
        }
      }

      &--tab {
        & > div {
          &:nth-child(4) {
            max-width: to-rem(100px);
          }
        }
      }
    }

    &__row {
      margin-bottom: to-rem(11px);
      border: 1px solid #C4C8D6;
      border-radius: 6px;
      padding: 0 to-rem(20px);
      min-height: to-rem(64px);
      display: flex;
      justify-content: space-between;

      .Paid, .Unpaid {
        &:before {
          content: "";
          display: block;
          width: to-rem(7px);
          height: to-rem(7px);
          background: #17D175;
          border-radius: 50%;
          margin-right: to-rem(8px);
        }
      }

      .Unpaid {
        &:before {
          background: #FFA511;
        }
      }

      > div {
        margin: 0 to-rem(10px);
        margin-right: to-rem(10px);
        flex: none;
        border-bottom: none;
        flex-grow: 1;
        color: #031B4E;
        font-weight: 600;
        font-size: to-rem(15px);
        text-align: left;
        display: flex;
        align-items: center;
        margin: 0;

        & p {
          margin: 0;
          color: #031B4E;
        }

        &:nth-child(1) {
          max-width: to-rem(130px);
        }

        &:nth-child(2) {
          max-width: to-rem(150px);
        }

        &:nth-child(3) {
          max-width: to-rem(220px);
        }

        &:nth-child(4) {
          max-width: to-rem(150px);
        }

        &:nth-child(5) {
          max-width: to-rem(150px);
        }

        &:nth-child(6) {
          max-width: to-rem(110px);
        }

        &:nth-child(7) {
          max-width: to-rem(120px);
          justify-content: flex-end;
        }

      }
    }

  }

  .billingCountry {
    height: to-rem(58px);
    border: 1px solid #d3d6e2;
    border-radius: to-rem(6px);
    //min-width: to-rem(400px);
    .Field__content {
      z-index: 1;
      overflow: visible;
      justify-content: flex-start;
      display: flex;
      align-items: center;

      & select {
        border: none !important;
        outline: none;
        margin-right: to-rem(10px);
        padding-right: to-rem(25px);
        padding-left: to-rem(50px);
        width: 100%;
        color: #444444;
        font-weight: 500;
        // background-position: calc(100% - 60px);
        display: flex;
        justify-content: space-between;
      }
    }

    input {
      pointer-events: none;
    }

    .Select {
      position: absolute;
      top: 50%;
      right: -35px;
      transform: translateY(-50%);
    }
  }

  .existingCard {
    justify-content: space-between;

    &__card {
      height: to-rem(58px);
      border: to-rem(1px) solid #c4c8d6;
      border-radius: to-rem(3px);
      flex: 1;
      align-items: center;
      padding: to-rem(15px);

      .card {
        &__name,
        &__number {
          margin-bottom: to-rem(10px);
          @include minTablet {
            margin-bottom: 0;
          }
        }
      }
    }

    .button {
      margin-top: to-rem(20px);

      @include minTablet {
        margin-top: 0;
      }
    }

    .width-half {
      width: 100%;
      @include minTablet {
        width: 50%;
      }
    }

    .button {
      @include minTablet {
        width: calc(25% - #{to-rem(40px)});
      }
    }
  }

  .amounts {
    .Card__body {
      height: to-rem(58px);
      align-items: center;
      cursor: pointer;
      transition: all ease 0.4s;

      &.active,
      &:hover {
        background: #0069ff;
        border-color: #0069ff;
        color: #ffffff;
      }

      &.active:hover {
        @include minDesktopSmall {
          background: #1c3aa9;
        }
      }
    }

    &--error {
      .Card__body {
        border-color: red;
      }
    }
  }

  .otherAmount {
    .Field__content {
      &:after {
        display: block;
        content: '$';
        position: absolute;
        font-size: to-rem(15px);
        top: 50%;
        transform: translateY(-50%);
        color: #676767;
        font-weight: 500;
        left: to-rem(20px);

        @include minDesktopSmall {
          left: to-rem(20px);
        }
      }
      input {
        padding-left: to-rem(30px);
      }
    }

    &.Field--with-error {
      .Field__content {
        &:after {
          top: calc(50% + #{to-rem(10px)});
        }
      }
    }
  }

  .amounts {
    .Card {
      width: 33%;
      @include minTablet {
        width: auto;
      }
    }
  }

  & .Invoices {

    .Modal__content {
      max-width: to-rem(687px);
    }

    &__tooltip {
      position: absolute;
      left: 100%;
      top: 0;
      display: block;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 5px;
      color: white;
      padding: to-rem(5px);
      opacity: 0;
      transition: opacity 0.3s ease;
      pointer-events: none;
      width: 100%;
    }

    &__tooltip-icon {
      cursor: pointer;
      position: absolute;
      top: to-rem(5px);
      right: to-rem(45px);

      &:hover {
        & ~ .Invoices__tooltip {
          opacity: 1;
        }
      }
    }
  }
}

.BillingProfile {
  .OrizontalScroll__scrollable {
    max-width: inherit;
    margin-right: to-rem(-20px);
  }

  &__empty {
    padding: to-rem(35px);
    border: 1px solid #c4c8d6;
  }

  .button-container {
    button {
      display: flex;
      align-items: center;

      img {
        margin-right: to-rem(5px);
      }
    }
  }

  .phone-ico {
    max-width: to-rem(16px);

    @include minDesktopSmall {
      max-width: to-rem(23px);
    }
  }
}

.BillingPage {
  & .usage {
    &__table {

      &-title {
        display: flex;
        align-items: center;

        img {
          margin-right: to-rem(8px);
        }
      }

      &-content,
      &-head {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        font-size: to-rem(15px);
        font-weight: 600;
        color: #031B4E;
      }

      &-head {
        padding: to-rem(15px) to-rem(20px);
        font-size: 12px;
        color: #444444;
        text-transform: uppercase;

      }

      &-content {
        padding: to-rem(15px) to-rem(20px);
        font-weight: 400;
        border: 1px solid #C4C8D6;
        border-radius: 6px;

        &:not(:last-child) {
          border-bottom: 1px solid #d3d6e2;
        }
      }
    }
  }
}
