.resizeModal {
	.Modal__content {
		max-width: to-rem(690px);
	}

	.slider {
		margin: 0 to-rem(-25px);
		padding: to-rem(14px) to-rem(25px) to-rem(60px);

		.mark-0 {
			margin-left: 10px;
		}

		.mark-5 {
			margin-right: 10px;
		}

		.example-mark {
			color: #0069FF;
			font-weight: 600;
			font-size: to-rem(15px);
			text-align: center;
			top: to-rem(54px);
			transform: translateX(-30%);

			&:before {
				content: "";
				position: absolute;
				width: 1.5px;
				height: 20px;
				background: #E7E9F0;
				border-radius: 3px;
				left: 50%;
				transform: translateX(-50%);
				bottom: to-rem(30px);
			}
		}
	}

	.otherAmountContainer {
		.Field {
			position: relative;

			input {
				padding: to-rem(12px);
			}

			&--with-error {
				input {
					padding-top: to-rem(25px);
					padding-left: to-rem(20px);
				}

				&:before {
					top: calc(50% + #{to-rem(6px)});
					left: to-rem(35px);
				}
			}
		}


		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input {
			-moz-appearance: textfield;
		}

		p,
		>div {
			flex-grow: 1;
			max-width: 50%;
		}

		p {
			font-weight: 600;
			font-size: to-rem(16px);
			color: #444444;
		}
	}
}

.slider {
	.example-thumb {
		.tooltip {
			bottom: calc(100% + 20px);
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			background: #031B4E;
			left: to-rem(-38px);
			width: to-rem(84px);
			height: to-rem(30px);
			font-weight: 600;
			font-size: to-rem(13px);
			text-align: center;
			color: #FFFFFF;
			border-radius: 6px;

			&:after {
				content: '';
				position: absolute;
				display: block;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0.5rem 0.5rem 0 0.5rem;
				border-color: #031B4E transparent transparent transparent;
			}
		}
	}
}
