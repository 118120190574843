.Modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 2;

  @include minTablet {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    width: 100%;
    max-width: to-rem(504px);
    box-shadow: 0px to-rem(10px) to-rem(40px) #00000012;
    border-radius: to-rem(8px);
    background: #ffffff;
    padding: to-rem(40px);
    z-index: 1;
    position: absolute;
  }

  &__large {
    .Modal__content {
      max-width: to-rem(1090px);
    }
  }

  &__middle {
    .Modal__content {
      max-width: to-rem(900px);
    }
  }

  &__small {
    .Modal__content {
      max-width: to-rem(607px);
    }
  }

  .overley {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.75);
  }

  &__header {
    margin-bottom: to-rem(40px);

    span.close {
      position: absolute;
      right: to-rem(15px);
      top: to-rem(14px);
      color: #d3d6e2;
      font-size: to-rem(24px);
      cursor: pointer;

      img {
        max-width: to-rem(14px);
      }
    }
  }
}
