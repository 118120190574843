.button {
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 0.57143rem;
    column-gap: 0.57143rem;
  }
  &--loading {
    background: #D3D6E2;
    pointer-events: none;
    .loader {
      position: absolute;
      left: 46%;
      top: 42%;
      transform: translate(-50%, -50%);
    }
  }
}
