.CountInput {
  height: to-rem(58px);
  width: 100%;
  border: 1px solid #C4C8D6;
  border-radius: to-rem(6px);
  padding: to-rem(15px);
  display: flex;
  justify-content: space-between;

  &__value {
    display: flex;
    align-items: center;
    height: to-rem(27px);
    font-weight: 400;
    font-size: to-rem(16px);
    color: #676767;
  }

  &__minus, &__plus {
    width: to-rem(27px);
    height: to-rem(27px);
    background: #FFFFFF;
    border: 1px solid #C4C8D6;
    border-radius: to-rem(6px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      width: to-rem(12px);
      height: to-rem(2px);
      background: #0069FF;
      border-radius: to-rem(1px);
    }
  }
  &__plus {
    &:before {
      content: "";
      display: block;
      height: to-rem(12px);
      width: to-rem(2px);
      background: #0069FF;
      border-radius: to-rem(1px);
      position: absolute;
    }
  }
}
