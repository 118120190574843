.OrizontalScroll {
  @include maxMobile {
    &__scrollable {
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: visible;

      > div {
        width: 150vw;
      }
    }
  }

  &__slider {
    background: #e1e4ec;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 75px;
    border-radius: 7px;
    padding: 0 4px;

    @include minTablet {
      display: none;
    }
  }

  input[type='range'] {
    width: 100%;
    margin: 2.5px 0;
    background-color: transparent;
    -webkit-appearance: none;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    background: #ffffff;
    border: 0;
    border-radius: 1.3px;
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  input[type='range']::-webkit-slider-thumb {
    margin-top: -3.4px;
    width: 10px;
    height: 10px;
    background: #0069ff;
    border: 0px solid rgba(66, 133, 244, 0);
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: #ffffff;
  }
  input[type='range']::-moz-range-track {
    background: #ffffff;
    border: 0;
    border-radius: 1.3px;
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  input[type='range']::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: #0069ff;
    border: 0px solid rgba(66, 133, 244, 0);
    border: 0;
    border-radius: 5px;
    cursor: pointer;
  }
  input[type='range']::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 2.5px 0;
    color: transparent;
    width: 100%;
    height: 3px;
    cursor: pointer;
  }
  input[type='range']::-ms-fill-lower {
    background: #d6d6d6;
    border: 0;
    border-radius: 2.6px;
  }
  input[type='range']::-ms-fill-upper {
    background: #ffffff;
    border: 0;
    border-radius: 2.6px;
  }
  input[type='range']::-ms-thumb {
    width: 10px;
    height: 10px;
    background: #0069ff;
    border: 0px solid rgba(66, 133, 244, 0);
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0px;
  }
  input[type='range']:focus::-ms-fill-lower {
    background: #ffffff;
  }
  input[type='range']:focus::-ms-fill-upper {
    background: #ffffff;
  }

  input[type='range']::-moz-range-progress {
    background: blue;
    border: 0;
    margin-top: 0;
  }

  @supports (-ms-ime-align: auto) {
    input[type='range'] {
      margin: 0;
    }
  }
}
