.CreateModal {
  .Modal__content {
    max-width: to-rem(690px);
  }

  .slider {
    margin: 0 to-rem(-25px);
    padding: to-rem(14px) to-rem(25px) to-rem(60px);

    .mark-0 {
      margin-left: 10px;
    }

    .mark-5 {
      margin-right: 10px;
    }

    .example-mark {
      color: #0069FF;
      font-weight: 600;
      font-size: to-rem(15px);
      text-align: center;
      top: to-rem(54px);
      transform: translateX(-30%);

      &:before {
        content: "";
        position: absolute;
        width: 1.5px;
        height: 20px;
        background: #E7E9F0;
        border-radius: 3px;
        left: 50%;
        transform: translateX(-50%);
        bottom: to-rem(30px);
      }
    }
  }

  .otherAmountContainer {
    input {
      padding: to-rem(12px);
    }

    .Field {
      position: relative;

      input {
        padding: to-rem(12px);
      }

      &--with-error {
        input {
          padding-top: to-rem(25px);
          padding-left: to-rem(20px);
        }

        &:before {
          top: calc(50% + #{to-rem(6px)});
          left: to-rem(20px);
        }
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input {
      -moz-appearance: textfield;
    }

    p,
    >div {
      flex-grow: 1;
      max-width: 50%;
    }

    p {
      font-weight: 600;
      font-size: to-rem(16px);
      color: #444444;
    }
  }

  .Choose__instance {
    input {
      height: to-rem(58px);
    }
  }

}
