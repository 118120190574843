@import './components/Instance/style';

.Dashboard {
  .firstProject {
    background: url('../../assets/images/dashboard/first-project-ill.svg');

    .Card__body {
      padding: to-rem(30px) 0 to-rem(50px);
      padding-left: to-rem(30px);

      h3 {
        color: #222222;
        margin: 0;
        font-weight: 600;
      }

      p {
        font-size: to-rem(15px);
        margin-top: to-rem(5px);
      }

      img {
        max-width: to-rem(85px);
        margin-right: to-rem(30px);
      }

      .ill {
        position: absolute;
        pointer-events: none;
        max-width: 90%;
        top: to-rem(10px);
        left: to-rem(10px);
      }

      @include minTablet {
        padding-left: to-rem(90px);
      }
    }
  }

  .ResourcesTab {
    ul {
      padding: 0;
      list-style: none;

      li {
        border: 1px solid #C4C8D6;
        border-radius: 6px;
        padding: to-rem(15px) to-rem(20px);
        margin-bottom: to-rem(11px);
        cursor: pointer;

        &:hover, &.active {
          border-color: #0069FF;
        }

        &.active {
          border-radius: 6px 6px 0 0;
        }

        & .instanceName {
          white-space: nowrap;
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
        }

        .name {
          display: flex;
          flex-direction: column;

          a {
            margin-left: 0;
            margin-bottom: to-rem(2px);
          }
        }

        .os {
          display: none;
          @include minTablet {
            display: flex;
          }
          img {
            width: to-rem(27px)
          }
        }

        .datacenter {
          display: none;
          @include minTablet {
            display: flex;
          }
          color: #676767;
          align-items: center;

          img {
            width: to-rem(34px);
            margin-right: to-rem(10px);
            margin-top: to-rem(3px);
          }
        }

        &.Instance-el {
          &__inactive, &__pending {
            .datacenter, .os, .ip {
              opacity: 0.4;
            }
          }
          &__pending {
            border-color: goldenrod;
          }
        }
      }

      .backups {
        img {
          width: to-rem(27px);
        }
      }

      .instanceIco__status {
        position: relative;

        .status {
          position: absolute;
          bottom: to-rem(8px);
          right: to-rem(7px);
        }
      }

      .Instance {
        &__active {
          width: to-rem(7px);
          height: to-rem(7px);
          background: #17d175;
          border-radius: 50%;
        }

        &__inactive {
          width: to-rem(7px);
          height: to-rem(7px);
          background: #ff2c00;
          border-radius: 50%;
        }

        &__pending {
          width: to-rem(7px);
          height: to-rem(7px);
          background: goldenrod;
          border-radius: 50%;
        }
      }

      .copy-wrapper {
        .copy {
          opacity: 0;
          transition: all 0.5s ease-in-out;
          font-size: to-rem(14px);
        }

        &:hover {
          .copy {
            opacity: 1;
          }
        }
      }

      .first-tag,
      .tag {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: to-rem(10px);
        width: to-rem(57px);
        height: to-rem(32px);
        background: #f8f8f8 0% 0% no-repeat padding-box;
        border: 1px solid #C4C8D6;
        border-radius: 3px;
        color: #676767;
        font-weight: 500;
        font-size: 15px;
      }

      .InfoBox__ico-image {

      }
    }

    .instance {
      flex-grow: 1;
      max-width: to-rem(300px);
    }

    .ip {
      flex-grow: 1;
      max-width: to-rem(100px);
    }

    .os {
      flex-grow: 1;
      max-width: to-rem(30px);
    }

    .datacenter {
      flex-grow: 1;
      max-width: to-rem(100px);
    }

    .tags {
      flex-grow: 1;
      max-width: to-rem(100px);
    }


    .heading {
      padding: 0 to-rem(20px);
      color: #444444;
      font-size: to-rem(12px);
      font-weight: 600;
      display: none;
      @include minTablet {
        display: flex;
      }
    }

    .ip-location {
      position: absolute;
      right: calc(100% + 7px);
      top: 50%;
      transform: translateY(-50%);
    }

    .InstanceDetails {
      border: 1px solid #c4c8d6;
      border-radius: 0 0 6px 6px;
      border-top: none;
      margin-top: to-rem(-11px);
      margin-bottom: to-rem(11px);
      padding: to-rem(30px) to-rem(20px);
      background: #F8F8F8;
      font-size: to-rem(15px);
      color: #676767;
      display: flex;
      justify-content: space-between;
      //align-items: center;
      flex-wrap: wrap;

      @include minTablet {
        flex-wrap: nowrap;
      }


      span,
      a {
        font-weight: 600;
      }

      .font-weight-regular {
        font-weight: 400;
      }

      > .flex {
        justify-content: space-between;
        flex-grow: 1;
        flex-wrap: wrap;
        margin-right: to-rem(50px);

        > div {
          margin: to-rem(15px) 0;
          margin-right: to-rem(25px);

          &:last-child {
            margin-right: 0;
          }

          > div {
            flex-grow: 1;
            margin-bottom: to-rem(15px);

            &:last-child {
              margin-bottom: 0;
            }

            > span {
              &:first-child {
                min-width: to-rem(70px);
                margin-right: to-rem(30px);
              }

              display: inline-block;
            }
          }
        }
      }

      .flex {
        > div > div {
          display: flex;

          > div {
            display: flex;
            justify-content: space-between;
            min-width: to-rem(170px);

            &.min-width-auto {
              min-width: auto;
            }

            span {
              font-weight: 400;
            }
          }
        }

      }

      .actions {
        background: #FFFFFF;
        border: 1px solid #C4C8D6;
        border-radius: 6px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-right: 0;
        flex-grow: 1;
        padding: to-rem(20px);
        max-width: to-rem(270px);
        height: to-rem(111px);
        margin-top: to-rem(20px);

        img {
          -webkit-transform: translate3d(0,0,0);
        }

        @include minTablet {
          margin-top: 0;
        }

        div {
          flex-grow: initial;
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          margin-bottom: 0;
          margin: to-rem(7px) 0;

          span.actions__item {
            font-size: to-rem(11px);
            margin-right: to-rem(7px);
            display: flex;
            align-items: center;
            min-width: to-rem(70px);
            cursor: pointer;

            img {
              margin-right: to-rem(5px);
            }
          }
        }
      }

      &__image {
        display: flex;
        align-items: center;

        img {
          width: to-rem(17px);
          margin-right: to-rem(5px);
        }
      }
    }

    .InstanceMenu {
      opacity: 0;
      height: 0;
      overflow: visible;
      position: absolute;
      pointer-events: none;
      top: calc(100% + 10px);
      right: -5px;
      transition: all 0.5s ease;
      z-index: 2;
      max-width: to-rem(185px);

      .Card__body {
        flex-direction: column;
        justify-content: flex-start;
        padding: to-rem(25px) to-rem(20px);

        :before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 to-rem(8px) to-rem(9px) to-rem(8px);
          border-color: transparent transparent #c4c8d6 transparent;
          position: absolute;
          bottom: 100%;
          right: 6px;
        }
        :after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 to-rem(7px) to-rem(9px) to-rem(7px);
          border-color: transparent transparent #ffffff transparent;
          position: absolute;
          bottom: calc(100% - 1px);
          right: 7px;
        }
      }

      a {
        color: #676767;
        font-size: to-rem(16px);
        margin-bottom: to-rem(20px);
        text-align: left;
        display: flex;
        align-items: center;

        > a {
          margin-bottom: 0;
        }

        img {
          margin-right: to-rem(7px);
          height: to-rem(14px)
        }

        &:hover {
          color: #0069ff;
        }

        &.delete {
          color: #ff2c00;
          margin-bottom: 0;
          padding-top: to-rem(20px);
        }
      }

      &--visible {
        opacity: 1;
        height: to-rem(350px);
        pointer-events: all;
      }
    }

    .InfoBox {
      cursor: pointer;

      &__content {
        display: flex;
      }

      &__tags {
        padding: to-rem(7px);
      }

      .tag {
        font-size: to-rem(13px);
        height: to-rem(26px);
        width: auto;
        padding: 0 to-rem(15px);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__links {
    margin: 0 to-rem(-12px);

    h3 {
      font-size: to-rem(20px);
      color: #444444;
      font-weight: 600;
      margin-left: to-rem(12px);
    }

    img {
      max-height: to-rem(25px);
    }

    .image {
      width: to-rem(38px);
      height: to-rem(40px);
      border: to-rem(1px) solid #d3d6e2;
      border-radius: to-rem(3px);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: to-rem(20px);
      transition: all 0.5s ease;
    }

    .flex-grow-1 a .flex {
      padding: to-rem(12px);

      p {
        margin-bottom: 0;
      }

      border-radius: 6px;
      border: 1px solid transparent;
      cursor: pointer;

      &:hover {
        border: 1px solid #0069FF;

        .image {
          background: #0069ff;

          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }

  .close {
    position: absolute;
    top: to-rem(17px);
    right: to-rem(17px);
    width: to-rem(11px);
    height: to-rem(11px);
    cursor: pointer;
    transform: rotate(45deg);

    &:after {
      content: '';
      display: block;
      width: to-rem(11px);
      height: to-rem(2px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #676767;
    }

    &:before {
      content: '';
      display: block;
      height: to-rem(11px);
      width: to-rem(2px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #676767;
    }
  }

  .Modal {
    .close {
      transform: rotate(0deg);

      &::after,
      &::before {
        display: none;
      }
    }
  }

  .createInstance {
    .boxes {
      overflow: auto;
      padding: to-rem(3px) 0;
      @include minTablet {
        max-width: inherit;
        margin: 0 to-rem(-15px);
      }

      .logo {
        width: to-rem(80px);
      }
    }

    .Card__body {
      padding: to-rem(30px);
      height: 100%;
      font-size: to-rem(15px);
      min-height: 206px;
      min-width: to-rem(280px);
      text-align: center;
      position: initial;

      img.ico {
        height: to-rem(107px);
        margin-top: to-rem(25px);
        margin-bottom: to-rem(15px);

        &--white {
          display: none;
        }
      }

      .star {
        position: absolute;
        top: to-rem(20px);
        right: to-rem(40px);
        height: to-rem(30px);
        margin-top: 0;
        cursor: pointer;

        &--white {
          display: none;
        }
      }


      h3 {
        color: #0069ff;
        font-size: to-rem(22px);
        font-weight: 600;
        margin-bottom: to-rem(8px);
        position: relative;

      }

      p {
        margin-bottom: 0;
        line-height: 1.4;
        color: #8a8a8a;
        font-size: to-rem(14px);
      }
    }

    &__card {
      position: relative;

      .Card__body {
        &:hover {
          background: #031B4E;
          border-color: #031B4E;

          h3, p {
            color: #ffffff;
          }

          .star {
            display: none;

            &--white {
              display: block;
            }
          }

          .ico {
            display: none;

            &--white {
              display: block;
            }
          }
        }
      }
    }

    .buttons {
      position: relative;
      margin-top: to-rem(10px);

      .button {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all 0.5s ease;

        &--blue {
          top: to-rem(50px);
        }
      }
    }

    .intancesBox {
      overflow: hidden;
      height: to-rem(320px);
      padding-top: to-rem(50px);

      &__image {
        min-height: to-rem(86px);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;
      }

      img, p {
        transition: all 0.5s ease;
      }

      > div {
        margin-bottom: to-rem(5px);
      }

      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: to-rem(40px);
        margin-top: 0;

        img {
          margin-right: to-rem(5px);
          opacity: 0;
          width: 0;
        }
      }

      &:hover {
        img {
          opacity: 0;
        }

        .intancesBox__image {
          margin-top: to-rem(-87px);
        }

        h3 img {
          opacity: 1;
          width: to-rem(40px);
        }

        .buttons .button {
          position: inherit;
          left: 0;
          top: 0;
          opacity: 1;
          margin-top: to-rem(10px);
        }
      }
    }
  }

  .activity-tab {
    width: 100%;
    .table {
      border: none;
      &__head {
        background: transparent;
        border: none;
        padding: to-rem(10px);
        justify-content: space-between;
        color: #444444;
        font-size: to-rem(12px);

        & > div {
          flex: none;
          flex-grow: 1;

          &:nth-child(1) {
            width: 100%;
            max-width: to-rem(100px);
          }

          &:nth-child(2) {
            width: 100%;
            max-width: to-rem(140px);
          }

          &:nth-child(4) {
            width: 100%;
            max-width: to-rem(140px);
          }

          &:nth-child(3) {
            width: 100%;
            max-width: to-rem(290px);
          }

          &:nth-child(5) {
            width: 100%;
            max-width: to-rem(110px);
            text-align: right;
          }
        }
      }
    }

    &__list {
      .table__row {
        margin-bottom: to-rem(11px);
        border: 1px solid #C4C8D6;
        border-radius: 6px;
        padding: 0 to-rem(10px);
        min-height: to-rem(64px);
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
      }
      .INFO {
        color: #0069FF;
        display: flex;
        align-items: center;
        img {
          margin-right: to-rem(5px);
        }
      }
      .ERROR {
        color: #FF2C00;
        display: flex;
        align-items: center;
        img {
          margin-right: to-rem(5px);
        }
      }
      .Scheduled, .Completed, .Started, .Created {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          width: to-rem(7px);
          height: to-rem(7px);
          border-radius: 50%;
          background: #0069ff;
          display: block;
          margin-right: to-rem(5px);
        }
      }

      .Completed, .Created {
        &::before {
          background: #17D175;
        }
      }

      .Started {
        &::before {
          background: #FFA511;
        }
      }

      &-item {
        margin: 0 to-rem(10px);
        flex: none;
        border-bottom: none;
        flex-grow: 1;

        & p {
          margin: 0;
          color: #031B4E;
        }

        &:nth-child(1) {
          width: 100%;
          max-width: to-rem(100px);
        }

        &:nth-child(2) {
          width: 100%;
          max-width: to-rem(140px);
        }

        &:nth-child(3) {
          width: 100%;
          max-width: to-rem(290px);
        }

        &:nth-child(4) {
          width: 100%;
          max-width: to-rem(140px);
        }

        &:nth-child(5) {
          width: 100%;
          max-width: to-rem(110px);
          text-align: right;
          p {
            width: 100%;
            text-align: right;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .Dashboard {
    .activity-tab {
      .table {
        &__head {
          & > div {
            &:nth-child(2) {
              width: to-rem(150px);
            }

            &:nth-child(4) {
              min-width: to-rem(70px);
            }
          }
        }
      }

      &__list {
        &-item {
          &:nth-child(2) {
            width: to-rem(150px);
          }

          &:nth-child(4) {
            min-width: unset;
          }
        }

        &--tab {
          & .activity-tab__list {
            &-item {
              &:nth-child(4) {
                min-width: to-rem(70px);
              }
            }
          }
        }
      }
    }
  }
}

.InstancesPassword {
  h2 {
    margin-top: to-rem(-40px);
    font-weight: 300;
    font-size: to-rem(38px);
    line-height: to-rem(46px);
    color: #444444;
  }
  p {
    font-weight: 400;
    font-size: to-rem(16px);
    line-height: to-rem(27px);
    color: #676767;
    margin-bottom: to-rem(22px);
  }

  .password {
    margin-bottom: to-rem(32px);
    h3 {
      font-weight: 600;
      font-size: to-rem(16.5px);
      line-height: to-rem(20px);
      color: #0069FF;
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }
    }
    > div {
      font-style: italic;
      font-weight: 400;
      font-size: to-rem(16px);
      line-height: to-rem(27px);
      color: #676767;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    span {
      font-weight: 600;
      font-size: to-rem(18px);
      line-height: to-rem(22px);
      color: #676767;
      cursor: pointer;
    }

    button, span {
      flex-grow: 1;
      width: 50%;
      text-align: center;
    }
  }
}


.ExtraIps, .VpcModal {
  position: absolute;
  top: calc(100% + 15px);
  width: 100%;
  min-width: to-rem(172px);
  left: 50%;
  transform: translateX(-50%);

  .Card__body {
    padding: to-rem(20px);
    flex-direction: column;
    font-size: to-rem(13px);
    color: #676767;

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 13px 8px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      bottom: calc(100% - 1px);
      right: calc(50% - 8px);
    }

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 13px 9px;
      border-color: transparent transparent #c4c8d6 transparent;
      position: absolute;
      bottom: 100%;
      right: calc(50% - 9px);
    }

    .line {
      margin: to-rem(10px) 0;
    }

    span {
      display: flex;
      align-items: center;
      font-weight: 600!important;

      img {
        margin-right: to-rem(7px);
        max-width: to-rem(15px);
      }
    }
  }
}

.VpcModal {
  min-width: to-rem(200px);
  .Card__body {
    padding: to-rem(13px);
  }
  span {
    padding: to-rem(7px);
    display: flex;
    align-items: center;
    &:hover {
      background: #F5F6F7;
      border-radius: 5px;
    }
    img {
      width: to-rem(20px);
      max-width: to-rem(20px) !important;
    }
  }
}

.vpc-container {
  min-width: auto !important;
}

.ClaimBonus {
  p {
    color: #676767;
    font-style: to-rem(16px);
  }
  input {
    margin-bottom: to-rem(15px);
  }

  &__title {
    color: #444;
    font-size: to-rem(38px);
    font-weight: 300;

    strong {
      font-weight: 400;
    }
  }
}
