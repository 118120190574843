.AddCredit {
  h3 {
    font-weight: 300;
    font-size: to-rem(38px);
    color: #444444;
  }
  h4 {
    font-weight: 600;
    font-size: to-rem(27px);
    margin-bottom: to-rem(25px);
    color: #031B4E;
  }
  .horizontal-slider {
    margin-top: to-rem(35px);
    bottom: to-rem(30px);
  }

  .slider {
    overflow: hidden;
    margin: 0 to-rem(-25px);
    padding: 0 to-rem(25px) to-rem(40px);

    .mark-0 {
      margin-left: 10px;
    }

    .mark-6 {
      margin-right: 10px;
    }

    .example-mark {
      color: #0069FF;
      font-weight: 600;
      font-size: to-rem(15px);
      text-align: center;
      top: to-rem(54px);
      transform: translateX(-30%);

      &:before {
        content: "";
        position: absolute;
        width: 1.5px;
        height: 20px;
        background: #E7E9F0;
        border-radius: 3px;
        left: 50%;
        transform: translateX(-50%);
        bottom: to-rem(30px);
      }
    }
  }

  .otherAmountContainer {
    p, > div {
      flex-grow: 1;
      max-width: 50%;
    }
    p {
      font-weight: 600;
      font-size: to-rem(16px);
      color: #444444;
    }
  }
  .paymentMethods {
    margin: 0 to-rem(-12.5px) to-rem(40px);
    > div {
      flex-grow: 1;
      max-width: 50%;
      padding: 0 to-rem(12.5px);
    }
    .button {
      background: #C4C8D6;
      border-radius: 6px;
      font-weight: 600;
      font-size: to-rem(16px);
      color: #031B4E;
      margin-bottom: to-rem(20px);

      &.active {
        background: #031B4E;
        color: #FFFFFF;
      }
    }

    .Card__body {
      font-size: to-rem(12px);
      text-align: center;
      letter-spacing: -0.02em;
      color: #676767;

      > div:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 10px 8px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        bottom: calc(100% - 1px);
        right: 50%;
        transform: translateX(50%);
      }

      > div:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 9px 10px 9px;
        border-color: transparent transparent #c4c8d6 transparent;
        position: absolute;
        bottom: 100%;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
}
