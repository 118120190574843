.RegisterPage {
  h2 {
    text-align: center;
    color: #142149;
    font-size: to-rem(41px);
    font-weight: bold;
    margin: 0 0 to-rem(20px);
  }

  .ill {
    img {
      position: absolute;
      display: none;

      @include minTablet {
        display: block;
      }
    }

    &__1 {
      right: to-rem(-500px);
      top: to-rem(230px);

      @include maxTablet {
        left: calc(100% + 7rem);
        max-width: to-rem(100px);
      }
      @include minDesktopSmall {
        top: to-rem(350px);
      }
    }

    &__2 {
      right: to-rem(-310px);
      top: to-rem(0px);
      @include maxTablet {
        left: calc(100% + 2rem);
        max-width: to-rem(70px);
      }
      @include minDesktopSmall {
        top: to-rem(140px);
      }
    }

    &__3 {
      top: to-rem(0px);
      left: to-rem(-260px);

      @include maxTablet {
        max-width: to-rem(250px);
      }

      @include minDesktopSmall {
        top: to-rem(-154px);
        left: to-rem(-520px);
      }
    }
  }
}
