.Monthpicker {
  position: relative;
  &__input {
    position: relative;
    img, .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    img {
      left: to-rem(15px);
    }

    .arrow {
      right: to-rem(15px);
      transform: translateY(-50%) rotate(-135deg);
      top: 47%
    }

    input {
      border: 1.5px solid #DBDBDB;
      border-radius: 6px;
      min-width: to-rem(240px);
      height: to-rem(58px);
      font-weight: 500;
      font-size: to-rem(16px);
      padding: 0 to-rem(40px);
      color: #444444;
      cursor: pointer;

      &:hover, &.active {
        border-color: #0069ff;
      }

    }
  }

  &__selector {
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    margin-top: to-rem(10px);
    width: 100%;
    position: absolute;
    background: #FFFFFF;

    .CountInput {
      scale: 0.9;
      margin: to-rem(10px) 0 0;
    }

    .MonthList {
      padding: to-rem(10px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        cursor: pointer;
        border: 1px solid #DBDBDB;
        margin-bottom: to-rem(5px);
        padding: to-rem(10px);
        border-radius: 6px;
        text-align: center;
        color: #444444;
        width: calc(50% - 2.5px);

        &.active, &:hover {
          border-color: #0069ff;
        }
      }
    }
  }
}
