.FloatingIps {
  .AddFloating {
    button {
      width: 50%;
    }
  }

  & .Select {
    cursor: pointer;

    & input {
      cursor: pointer;
    }
  }

  &__list {
    .list-table {
      border: 1px solid #c4c8d6;
      border-radius: 3px;

      &__heading,
      &__row {
        display: grid;
        grid-template-columns: to-rem(150px) repeat(5, 1fr);
        padding: 5px to-rem(10px) 5px to-rem(40px);
      }

      &__row {
        align-items: center;

        >div {
          padding: 0;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .Instance__title-ico {
      width: auto;
      padding-right: to-rem(15px);
    }
  }

  .more {
    grid-column: 7;
    padding-right: 15px !important;
  }

  .ip-icon {
    & img {
      position: relative;
      z-index: 2;
    }

    &:hover {
      & .ip-address {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .ip-address {
    z-index: 1;
    position: absolute;
    background-color: white;
    top: 0;
    left: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 10px 10px 10px 20px;
    width: to-rem(100px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
}

.Vpc {
  .list-table {
    border: 1px solid #c4c8d6;
    border-radius: 3px;

    & .button {
      margin-left: auto;
    }

    &__heading,
    &__row {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      padding: 5px to-rem(10px) 5px to-rem(40px);
      min-height: to-rem(45px);

      & div {
        &:not(:last-of-type) {
          max-width: to-rem(280px);
        }
      }
    }

    &__heading {
      color: #000000;
    }

    &__row {
      border-bottom: none;
      align-items: center;

      &-wrapper {
        border-bottom: 1px solid #d3d6e2;
      }

      >div {
        padding: 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    & .button {
      &--hide {
        visibility: hidden;
        pointer-events: none;
      }
    }

    &__btn-toggler {
      position: absolute;
      background: none;
      outline: none;
      border: 1px solid black;
      width: 20px;
      height: 20px;
      left: -25px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after,
      &::before {
        position: absolute;
        display: block;
        content: '';
        background: black;
        transition: all 0.3s ease;
      }

      &::after {
        width: 12px;
        height: 2px;
        top: calc(50% - 1px);
        left: calc(50% - 6px);
      }

      &::before {
        width: 2px;
        height: 12px;
        top: calc(50% - 6px);
        left: calc(50% - 1px);
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &--open {
        &::before {
          transform: rotate(90deg);
          opacity: 0;
        }
      }
    }

    .more {
      margin-left: auto;
      margin-right: to-rem(20px);
    }
  }

  &__LearnAbout {
    .row {
      margin: 0 to-rem(-40px);
    }
  }

  &__item {
    .card {
      height: to-rem(87px);
    }
  }

  &__acl-list {
    height: 0px;
    overflow: hidden;

    & .list-table {
      border: none;

      &__heading,
      &__row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 5px to-rem(10px) 5px to-rem(40px);
        min-height: to-rem(45px);

        .deleteKey {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: to-rem(25px);
          cursor: pointer;
        }
      }

      &__heading {
        border-bottom: none;
      }
    }

    &-title {
      padding: 5px to-rem(10px) 5px to-rem(40px);
      margin: 0;
    }

    &--open {
      height: auto;
      overflow: visible;
    }
  }

  &__acl-rules-list {
    &-item {
      display: grid;
      grid-template-columns: 20px repeat(4, 1fr) 55px;
      gap: to-rem(35px) to-rem(20px);
      align-items: center;

      &:not(:first-child) {
        margin-top: to-rem(20px);
      }

      &:not(:last-child) {
        border-bottom: 1px solid grey;
        padding-bottom: to-rem(30px);
      }

      &-drag {
        grid-column: 1;
        grid-row: 1/3;
        cursor: grab;
      }

      &-actions {
        grid-column: 6;
        grid-row: 1/3;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .delete {
          color: #ff2c00;
        }
      }
    }
  }

  & .create-rule {
    & .Modal {
      overflow-y: hidden;
      min-height: to-rem(700px);
      top: -100px;
    }

    & textarea {
      resize: none;
      width: 100%;
      max-height: to-rem(75px);
    }
  }
}

.LoadBalancers {
  .Select {
    & input {
      cursor: pointer;
    }
  }

  .Settings {
    &__row {
      min-height: to-rem(80px);
      border-bottom: 1px solid #c4c8d6;

      &:first-child {
        margin-top: to-rem(-40px);
      }
    }
  }

  &__ip-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &-item {
      background-color: lightgray;
      padding: 2px 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-text {
        color: black;
        margin: 0px;
        cursor: pointer;
      }

      &-cross {
        display: block;
        margin-left: 5px;
        cursor: pointer;
      }

      &-dropdown {
        display: none;
        z-index: 2;
        position: absolute;
        bottom: -70px;
        left: 0;
        flex-direction: column;
        width: 100%;
        background-color: #fafafa;
        border: 1px solid #d3d6e2;

        & span {
          margin-top: 5px;
          cursor: pointer;

          &:hover {
            background-color: lightgray;
            color: black;
          }
        }

        &--open {
          display: flex;
        }
      }
    }
  }

  & .create-lb {
    @include maxMobile {
      justify-content: flex-start;
    }
  }
}

.NetworkPage {
  & .modal-inner {
    & input {
      &::placeholder {
        color: #999999;
      }
    }

    & .Select {
      &__offering {
        & .Field__content {
          cursor: pointer;
        }
      }

      & .Field__content {
        color: #444444;
      }
    }
  }
}

.toggle {
  &-tooltip {
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    height: auto;
    padding: 5px 5px;
    position: absolute;
    width: 150px;
    top: -50px;
    right: calc(50% - 77.5px);
    pointer-events: none;

    &::after {
      position: absolute;
      content: '';
      bottom: -9.8px;
      display: flex;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 7.5px 0 7.5px;
      border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
      left: calc(50% - 7.5px);
    }
  }

  &-tooltip-ico {
    position: relative;
    z-index: 10;
    cursor: pointer;

    &:hover+.toggle-tooltip {
      opacity: 1;
      pointer-events: visible;
    }
  }

  &-switch {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 25px;

    & input[type='checkbox'] {
      display: none;
    }

    & .switch {
      position: absolute;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;

      &::before {
        position: absolute;
        content: '';
        left: 2px;
        top: 2px;
        width: 21px;
        height: 21px;
        background-color: white;
        border-radius: 50%;
        transition: transform 0.3s ease;
      }
    }
  }

  .toggle-switch input[type='checkbox']:checked+.switch::before {
    transform: translateX(25px);
    background-color: white;
  }

  .toggle-switch input[type='checkbox']:checked+.switch {
    background-color: #0069ff;
  }
}

.Public-ips {
  .list-table {
    border: 1px solid #c4c8d6;
    border-radius: 3px;

    &__heading,
    &__row {
      padding: 5px to-rem(10px) 5px to-rem(40px);
      min-height: to-rem(45px);

      & div {

        // flex: unset;
        &:not(:last-of-type) {
          max-width: 280px;
        }
      }
    }

    &__row {
      align-items: center;

      >div {
        padding: 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    & .button {
      &--hide {
        visibility: hidden;
        pointer-events: none;
      }
    }
  }
}

.Nics {
  .list-table {
    border: 1px solid #c4c8d6;
    border-radius: 3px;

    & .button {
      margin-left: auto;
    }

    &__heading,
    &__row {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      padding: 5px to-rem(10px) 5px to-rem(40px);
      min-height: to-rem(45px);

      & div {
        &:not(:last-of-type) {
          max-width: to-rem(280px);
        }
      }
    }

    &__row {
      border-bottom: none;
      align-items: center;

      &-wrapper {
        border-bottom: 1px solid #d3d6e2;
      }

      >div {
        padding: 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    & .button {
      &--hide {
        visibility: hidden;
        pointer-events: none;
      }
    }

    &__btn-toggler {
      position: absolute;
      background: none;
      outline: none;
      border: 1px solid black;
      width: 20px;
      height: 20px;
      left: -25px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after,
      &::before {
        position: absolute;
        display: block;
        content: '';
        background: black;
        transition: all 0.3s ease;
      }

      &::after {
        width: 12px;
        height: 2px;
        top: calc(50% - 1px);
        left: calc(50% - 6px);
      }

      &::before {
        width: 2px;
        height: 12px;
        top: calc(50% - 6px);
        left: calc(50% - 1px);
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &--open {
        &::before {
          transform: rotate(90deg);
          opacity: 0;
        }
      }
    }
  }

  &__secondary {
    height: 0px;
    overflow: hidden;

    & .list-table {
      border: none;

      &__heading,
      &__row {
        display: flex;
        padding: 5px to-rem(10px) 5px to-rem(40px);
        min-height: to-rem(45px);
      }

      &__heading {
        border-bottom: none;
      }
    }

    &-title {
      padding: 5px to-rem(10px) 5px to-rem(40px);
      margin: 0;
    }

    &--open {
      height: auto;
      overflow: visible;
    }
  }

  & .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 425px) {
  .NetworkPage {
    width: 90%;

    & .Firewalls {
      .add-rule {
        justify-content: flex-start;
      }
    }

    & .Networks {
      .create-button {
        justify-content: flex-start;
      }
    }
  }
}

// @media screen and(max-width:475px) {
//   .NetworkPage {
//     & .Nics {
//       .list-table {
//         &__heading,
//         &__row {
//           & div {
//             &:not(:last-of-type) {
//               max-width: to-rem(100px);
//             }
//           }
//         }
//       }
//     }
//   }
// }

.Networks {
  .title_container {
    width: to-rem(860px);
    display: flex;
    flex-direction: column;
    row-gap: to-rem(24px);
  }

  .create_button {
    height: to-rem(52px);
    width: to-rem(230px);
    font-size: to-rem(15px);
    font-weight: 600;
  }

  .Listed_networks {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(40px);
  }
}

.Location_with_networks {
  display: flex;
  flex-direction: column;
  row-gap: to-rem(30px);

  .Location_content {
    display: flex;
    align-items: center;
    column-gap: to-rem(10px);
  }

  .Network_list {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(20px);

    .List_header {
      display: flex;
      font-size: 12px;
      font-weight: 600;
      color: #444444;

      &>div {
        &:nth-child(1) {
          width: to-rem(340px);
        }

        &:nth-child(2) {
          width: to-rem(290px);
        }

        &:nth-child(3) {
          width: to-rem(250px);
        }

        &:nth-child(4) {
          width: to-rem(280px);
        }
      }
    }

    .List_body {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(11px);

      .list_item {
        display: flex;
        align-items: center;
        border: 1px solid #C4C8D6;
        border-radius: 6px;
        padding: to-rem(22px) to-rem(20px);
        font-size: to-rem(16px);
        color: #444444;
        font-weight: 400;

        &:hover {
          border-color: #0069FF;
        }

        .delete_infobox {
          padding: to-rem(8px) 0;
          border-radius: to-rem(6px);
        }

        .Main_content {
          display: flex;
          align-items: center;
          cursor: pointer;

          &>div {
            &:nth-child(1) {
              width: to-rem(320px);
              display: flex;
              align-items: center;
              column-gap: to-rem(12px);
              font-weight: 600;
            }

            &:nth-child(2) {
              width: to-rem(290px);
              display: flex;
              align-items: center;
              column-gap: to-rem(8px);

              img {
                cursor: pointer;
              }
            }

            &:nth-child(3) {
              width: to-rem(250px);
            }

            &:nth-child(4) {
              width: to-rem(260px);
              color: #676767;
            }
          }
        }
      }
    }
  }
}

.Create_network_modal {
  display: flex;
  flex-direction: column;
  row-gap: to-rem(25px);

  .modal_section {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(15px);
  }

  .modal_button {
    width: to-rem(220px);
    height: to-rem(58px);
    background: #EEEFF3;
    color: #676767;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    font-size: to-rem(18px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cancel_button {
    background: #EEEFF3;
    color: #676767;
  }

  .confirm_button {
    background: #0069FF;
    color: #ffffff;
  }
}

.NetworksWithLocation_container {
  display: flex;
  flex-direction: column;
  row-gap: to-rem(40px);
}

.Choose_region_container {
  position: relative;

  input {
    width: 100%;
    border: 1px solid #C4C8D6;
    border-radius: 6px;
    height: to-rem(58px);
    padding-left: to-rem(20px);
    font-size: to-rem(16px);
  }

  input::placeholder {
    font-weight: 400;
    font-size: to-rem(16px);
    color: #676767;
  }

  .arrow-cont {
    &:before {
      z-index: 10;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 7px 7px;
      border-color: transparent transparent #c4c8d6 transparent;
      position: absolute;
      top: 115%;
      right: 92%;
    }

    &:after {
      z-index: 10;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 7px 7px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      top: 117%;
      right: 92%;
    }
  }

  .location_container {
    position: absolute;
    border: 1px solid #C4C8D6;
    border-radius: 6px;
    padding-left: to-rem(20px);
    margin-top: to-rem(15px);
    margin-bottom: to-rem(15px);
    width: 100%;
    z-index: 2;
    background-color: white;
    max-height: to-rem(280px);
    overflow: scroll;

    .locations_item {
      cursor: pointer;
    }
  }
}

.Network_details {
  display: flex;
  flex-direction: column;
  row-gap: to-rem(40px);

  .Header_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .Title_container {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(24px);
    }

    .Buttons_container {
      display: flex;
      column-gap: to-rem(20px);

      button {
        width: to-rem(150px);
        height: to-rem(52px) !important;
        border-radius: to-rem(6px);
      }

      .button_disabled {
        opacity: 0.5;
      }

      .Details_delete_button {
        border-radius: to-rem(6px);
      }

      .Details_delete_button::after {
        top: calc(100% - 10px);
      }
    }
  }

  .Info_section {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(20px);

    .Info_container {
      display: flex;
      column-gap: to-rem(160px);

      .Info_column {
        display: flex;
        flex-direction: column;
        row-gap: to-rem(24px);
        color: #676767;
        font-size: to-rem(16px);

        span {
          font-weight: 700;
        }
      }
    }
  }

  .Resources_section {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(40px);

    .List_header {
      display: flex;
      font-weight: 600;
      font-size: to-rem(12px);

      &>div {
        &:nth-child(1) {
          width: to-rem(375px);
        }

        &:nth-child(2) {
          width: to-rem(355px);
        }

        &:nth-child(3) {
          width: to-rem(350px);
        }
      }
    }

    .List_container {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(20px);
    }

    .List_body {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(11px);
    }

    .List_item {
      display: flex;
      align-items: center;
      border: 1px solid #C4C8D6;
      border-radius: to-rem(6px);
      padding: to-rem(13px) to-rem(24px);

      img {
        cursor: pointer;
      }

      &>div {
        &:nth-child(1) {
          width: to-rem(350px);
          display: flex;
          align-items: center;
        }

        &:nth-child(2) {
          width: to-rem(355px);
          display: flex;
          align-items: center;
          column-gap: to-rem(10px);
        }

        &:nth-child(3) {
          width: to-rem(355px);
          display: flex;
          align-items: center;
          column-gap: to-rem(10px);
        }
      }
    }
  }
}

.List_popup {
  left: 85% !important;
  margin-top: to-rem(10px);
}

.Disabled_Delete_Item {
  opacity: 0.5;
}

.Empty_container {
  display: flex;
  flex-direction: column;
  row-gap: to-rem(30px);
  align-items: center;
  margin-top: to-rem(100px);

  span {
    width: 55%;
    text-align: center;
  }
}

.Extra_Ips_header {
  display: flex;
  justify-content: space-between;
  column-gap: 140px;
  margin-bottom: to-rem(45px);
  line-height: to-rem(27px);

  button {
    width: to-rem(226px);
    height: to-rem(52px);
    font-weight: 600;
    font-size: to-rem(15px);
    margin-top: to-rem(25px);
  }
}

.ExtraIP_listing {
  display: flex;
  flex-direction: column;
  row-gap: to-rem(20px);

  .List_header {
    display: flex;
    color: #444444;
    font-size: to-rem(12px);
    font-weight: 600;

    &>div {
      &:nth-child(1) {
        width: to-rem(480px);
        display: flex;
        align-items: center;
      }

      &:nth-child(2) {
        width: to-rem(700px);
      }
    }
  }

  .List_body {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(11px);

    .List_item {
      border: 1px solid #C4C8D6;
      border-radius: to-rem(6px);
      display: flex;
      padding: to-rem(13px) to-rem(20px);

      &:hover {
        border-color: #0069FF;
      }

      &>div {
        &:nth-child(1) {
          min-width: to-rem(460px);
          display: flex;
          align-items: center;
          column-gap: to-rem(12px);
          font-weight: 600;
          font-size: to-rem(16px);
          color: #444444;
        }

        &:nth-child(2) {
          min-width: to-rem(500px);
        }

        &:nth-child(3) {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: flex-end;
        }
      }
    }
  }
}

.Instance_Select {
  .options_container {
    &:before {
      z-index: 10;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px;
      border-color: #c4c8d6 transparent transparent transparent;
      position: absolute;
      bottom: 94%;
      right: 94%;
    }

    &:after {
      z-index: 10;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px;
      border-color: #ffffff transparent transparent transparent;
      position: absolute;
      bottom: 98%;
      right: 94%;
    }

    .options_content {
      position: absolute;
      background: white;
      z-index: 1;
      bottom: 140%;
      border: 1px solid #C4C8D6;
      border-radius: 6px;
      padding: to-rem(12px) to-rem(20px);
      max-height: to-rem(250px);
      overflow: scroll;
    }

    .triangle_position {
      bottom: 140%;
    }

    .triangle_position_assign {
      bottom: 127%;
    }

    .List_item_empty {
      width: to-rem(340px);
      height: to-rem(38px);
      display: flex;
      align-items: center;
    }
  }

  .item {
    cursor: pointer;
  }

  .Search_container {
    position: relative;

    img {
      position: absolute;
      left: to-rem(17px);
      top: to-rem(10px);
    }

    .Instance_search {
      width: to-rem(380px);
      height: to-rem(42px);
      border: 1px solid #C4C8D6;
      border-radius: to-rem(6px);
      padding-left: to-rem(52px);
    }

    .Instance_search::placeholder {
      font-size: to-rem(15px);
      color: #585757;
    }
  }

  .Search_container_assign {
    position: relative;

    img {
      position: absolute;
      left: to-rem(17px);
      top: to-rem(17px);
    }

    .Instance_search {
      width: to-rem(470px);
      height: to-rem(58px);
      border: 1px solid #C4C8D6;
      border-radius: to-rem(6px);
      padding-left: to-rem(53px);
    }

    .Instance_search::placeholder {
      font-size: to-rem(15px);
      color: #585757;
    }
  }
}

.status {
  position: absolute;
  bottom: to-rem(8px);
  right: to-rem(7px);
}

.Instance {
  &__active {
    width: to-rem(7px);
    height: to-rem(7px);
    background: #17d175;
    border-radius: 50%;
  }

  &__inactive {
    width: to-rem(7px);
    height: to-rem(7px);
    background: #ff2c00;
    border-radius: 50%;
  }

  &__pending {
    width: to-rem(7px);
    height: to-rem(7px);
    background: goldenrod;
    border-radius: 50%;
  }
}

.Instance_info_container {
  display: flex;
  flex-direction: column;
  row-gap: to-rem(5px);

  .Instance_info {
    display: flex;
    column-gap: to-rem(10px);
  }
}

.Seleted_item_container {
  width: to-rem(470px);
  height: to-rem(58px);
  padding-left: to-rem(20px);
  border: 1px solid #C4C8D6;
  border-radius: to-rem(6px);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Firewalls_list {
  display: flex;
  flex-direction: column;
  row-gap: to-rem(30px);

  .Firewalls_list_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 150px;

    .Title_description {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(24px);
    }

    button {
      min-width: to-rem(226px);
      height: to-rem(52px);
      font-weight: 600;
      font-size: to-rem(15px);
    }
  }

  .Firewalls_table {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(20px);

    .List_header {
      font-size: to-rem(12px);
      color: #444444;
      font-weight: 600;
      display: flex;

      &>div {
        &:nth-child(1) {
          width: to-rem(340px);
        }

        &:nth-child(2) {
          width: to-rem(270px);
        }

        &:nth-child(3) {
          width: to-rem(264px);
        }

        &:nth-child(4) {
          width: to-rem(280px);
        }
      }
    }

    .List_body {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(12px);

      .List_item {
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #C4C8D6;
        border-radius: 6px;
        padding: to-rem(17px) to-rem(20px) to-rem(16px) to-rem(24px);
        font-size: to-rem(15px);
        font-weight: 600;
        color: #444444;

        &:hover {
          border-color: #0069FF;
        }

        .Main_content {
          display: flex;
          align-items: center;
          cursor: pointer;

          &>div {
            &:nth-child(1) {
              width: to-rem(316px);
              display: flex;
              column-gap: to-rem(16px);
              align-items: center;
              font-size: to-rem(16px);
            }

            &:nth-child(2) {
              width: to-rem(270px);
            }

            &:nth-child(3) {
              width: to-rem(264px);
            }

            &:nth-child(4) {
              width: to-rem(250px);
              color: #676767;
            }
          }
        }

        .more {
          flex-grow: 1;
          display: flex;
          align-items: center;
          width: 90px;
          justify-content: flex-end;
        }
      }
    }
  }
}

.Title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: to-rem(140px);
  margin-bottom: to-rem(40px);

  .Title_content {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(24px);

    .Title-desc {
      width: to-rem(850px);
    }
  }

  button {
    font-weight: 600;
    font-size: 15px;
    min-width: to-rem(226px);
    height: to-rem(52px);
  }
}

.Firewall_rules {
  display: flex;
  flex-direction: column;
  row-gap: to-rem(30px);

  .Rules_list {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(20px);

    .List_header {
      font-weight: 600;
      font-size: 12px;
      color: #444444;
      display: flex;

      &>div {
        &:nth-child(1) {
          width: to-rem(180px);
        }

        &:nth-child(2) {
          width: to-rem(260px);
        }

        &:nth-child(3) {
          width: to-rem(250px);
        }
      }
    }

    .List_body {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(20px);

      .List_item_display {
        border: 1px solid #C4C8D6;
        border-radius: to-rem(6px);
        padding: to-rem(10px) to-rem(20px);
        font-size: to-rem(16px);
        display: flex;
        align-items: center;
        width: 100%;

        &>div {
          &:nth-child(1) {
            width: to-rem(160px);
            color: #676767;
          }

          &:nth-child(2) {
            width: to-rem(260px);
            color: #444444;
          }

          &:nth-child(3) {
            display: flex;
            column-gap: to-rem(11px);
            row-gap: to-rem(11px);
            display: flex;
            flex-wrap: wrap;
            max-width: to-rem(500px);

            .Source_item {
              padding: to-rem(8px) to-rem(16px);
              background: #F8F8F8;
              border: 1px solid #C4C8D6;
              border-radius: 5px;
              font-weight: 600;
              font-size: 15px;
              color: #676767;
              display: flex;
              align-items: center;
              column-gap: to-rem(8px);
            }
          }

          &:nth-child(4) {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;

            .List_popup {
              left: 97% !important;
            }
          }
        }
      }

      .List_item_edit {
        border: 1px solid #C4C8D6;
        border-radius: to-rem(6px);
        padding: to-rem(16px) to-rem(20px);
        font-size: to-rem(16px);
        display: flex;
        align-items: center;
        width: 100%;

        &>div {
          &:nth-child(1) {
            width: to-rem(160px);

            .Select_Container {
              width: to-rem(140px);
              height: to-rem(48px);

              .Selected {
                height: to-rem(48px);
              }
            }
          }

          &:nth-child(2) {
            width: to-rem(260px);

            input {
              border: 1px solid #C4C8D6;
              border-radius: to-rem(6px);
              width: to-rem(141px);
              height: to-rem(48px);
              padding: to-rem(14px) to-rem(20px);
              font-size: to-rem(16px);
              color: #444444;
            }
          }

          &:nth-child(3) {
            .Source_input {
              width: to-rem(650px);
              display: flex;
              column-gap: to-rem(11px);
              row-gap: to-rem(7px);
              padding: to-rem(7px) to-rem(14px);
              border: 1px solid #C4C8D6;
              border-radius: to-rem(6px);
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              input {
                flex-grow: 1;
                height: to-rem(32px);
                border: unset;
                font-size: to-rem(13px);
                font-weight: 600;
                color: #676767;
              }

              .Select_menu {
                display: flex;
                flex-direction: column;
                row-gap: to-rem(11px);
                position: absolute;
                top: 125%;
                left: 0px;
                background: #FFFFFF;
                border: 1px solid #C4C8D6;
                z-index: 3;
                border-radius: to-rem(6px);
                padding: to-rem(21px) to-rem(20px);
                width: to-rem(474px);

                .Search_list {
                  display: flex;
                  flex-direction: column;
                  row-gap: to-rem(11px);


                  .Search_list_item {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    column-gap: to-rem(12px);

                    .Icon_container {
                      width: to-rem(42px);
                      display: flex;
                      justify-content: space-around;

                      span {
                        top: to-rem(23px);
                      }
                    }
                  }
                }

                &:before {
                  z-index: 10;
                  content: "";
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 7px 7px 7px;
                  border-color: transparent transparent #c4c8d6 transparent;
                  position: absolute;
                  bottom: 100%;
                  right: 94%;
                }

                &:after {
                  z-index: 10;
                  content: "";
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 7px 7px 7px;
                  border-color: transparent transparent #ffffff transparent;
                  position: absolute;
                  top: -5px;
                  right: 94%;
                }
              }
            }

            .Source_item {
              padding: to-rem(8px) to-rem(16px);
              background: #F8F8F8;
              border: 1px solid #C4C8D6;
              border-radius: 5px;
              font-weight: 600;
              font-size: 15px;
              color: #676767;
              display: flex;
              align-items: center;
              column-gap: to-rem(8px);
              position: relative;
              cursor: pointer;

              span {
                white-space: nowrap;
              }

              .x-icon {
                position: absolute;
                display: none;
                left: 94%;
                bottom: 70%;
              }

              &:hover {
                .x-icon {
                  display: block;
                }
              }
            }
          }

          &:nth-child(4) {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .Rules_list_ICMP {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(20px);

    .List_header {
      font-weight: 600;
      font-size: 12px;
      color: #444444;
      display: flex;

      &>div {
        &:nth-child(1) {
          width: to-rem(180px);
        }

        &:nth-child(2) {
          width: to-rem(120px);
        }

        &:nth-child(3) {
          width: to-rem(150px);
        }
      }
    }

    .List_body {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(20px);

      .List_item_display {
        border: 1px solid #C4C8D6;
        border-radius: to-rem(6px);
        padding: to-rem(10px) to-rem(20px);
        font-size: to-rem(16px);
        display: flex;
        align-items: center;
        width: 100%;

        &>div {
          &:nth-child(1) {
            width: to-rem(160px);
            color: #676767;
          }

          &:nth-child(2) {
            width: to-rem(120px);
            color: #444444;
          }

          &:nth-child(3) {
            width: to-rem(140px);
            color: #444444;
          }

          &:nth-child(4) {
            display: flex;
            column-gap: to-rem(11px);

            .Source_item {
              padding: to-rem(8px) to-rem(16px);
              background: #F8F8F8;
              border: 1px solid #C4C8D6;
              border-radius: 5px;
              font-weight: 600;
              font-size: 15px;
              color: #676767;
              display: flex;
              align-items: center;
              column-gap: to-rem(8px);
            }
          }

          &:nth-child(5) {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;

            .List_popup {
              left: 97% !important;
            }
          }
        }
      }

      .List_item_edit {
        border: 1px solid #C4C8D6;
        border-radius: to-rem(6px);
        padding: to-rem(16px) to-rem(20px);
        font-size: to-rem(16px);
        display: flex;
        align-items: center;
        width: 100%;

        &>div {
          &:nth-child(1) {
            width: to-rem(160px);

            .Select_Container {
              width: to-rem(140px);
              height: to-rem(48px);

              .Selected {
                height: to-rem(48px);
              }
            }
          }

          &:nth-child(2) {
            width: to-rem(120px);

            input {
              border: 1px solid #C4C8D6;
              border-radius: to-rem(6px);
              width: to-rem(104px);
              height: to-rem(48px);
              padding: to-rem(14px) to-rem(20px);
              font-size: to-rem(16px);
              color: #444444;
            }
          }

          &:nth-child(3) {
            width: to-rem(140px);

            input {
              border: 1px solid #C4C8D6;
              border-radius: to-rem(6px);
              width: to-rem(104px);
              height: to-rem(48px);
              padding: to-rem(14px) to-rem(20px);
              font-size: to-rem(16px);
              color: #444444;
            }
          }

          &:nth-child(4) {
            .Source_input {
              width: to-rem(650px);
              display: flex;
              column-gap: to-rem(11px);
              row-gap: to-rem(7px);
              padding: to-rem(7px) to-rem(14px);
              border: 1px solid #C4C8D6;
              border-radius: to-rem(6px);
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              input {
                flex-grow: 1;
                height: to-rem(32px);
                border: unset;
                font-size: to-rem(13px);
                font-weight: 600;
                color: #676767;
              }

              .Select_menu {
                display: flex;
                flex-direction: column;
                row-gap: to-rem(11px);
                position: absolute;
                top: 125%;
                left: 0px;
                background: #FFFFFF;
                border: 1px solid #C4C8D6;
                z-index: 3;
                border-radius: to-rem(6px);
                padding: to-rem(21px) to-rem(20px);
                width: to-rem(474px);

                .Search_list {
                  display: flex;
                  flex-direction: column;
                  row-gap: to-rem(11px);


                  .Search_list_item {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    column-gap: to-rem(12px);

                    .Icon_container {
                      width: to-rem(42px);
                      display: flex;
                      justify-content: space-around;

                      span {
                        top: to-rem(23px);
                      }
                    }
                  }
                }

                &:before {
                  z-index: 10;
                  content: "";
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 7px 7px 7px;
                  border-color: transparent transparent #c4c8d6 transparent;
                  position: absolute;
                  bottom: 100%;
                  right: 94%;
                }

                &:after {
                  z-index: 10;
                  content: "";
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 7px 7px 7px;
                  border-color: transparent transparent #ffffff transparent;
                  position: absolute;
                  top: -5px;
                  right: 94%;
                }
              }
            }

            .Source_item {
              padding: to-rem(8px) to-rem(16px);
              background: #F8F8F8;
              border: 1px solid #C4C8D6;
              border-radius: 5px;
              font-weight: 600;
              font-size: 15px;
              color: #676767;
              display: flex;
              align-items: center;
              column-gap: to-rem(8px);
              position: relative;
              cursor: pointer;

              span {
                white-space: nowrap;
              }

              .x-icon {
                position: absolute;
                display: none;
                left: 94%;
                bottom: 70%;
              }

              &:hover {
                .x-icon {
                  display: block;
                }
              }
            }
          }

          &:nth-child(5) {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.Name_input_container {
  display: flex;
  flex-direction: column;
  row-gap: to-rem(24px);
}

.full_button {
  margin-top: to-rem(30px);
  width: 100%;
  height: to-rem(58px);
}


.Instances_tab {
  position: relative;

  .Search_section {
    img {
      position: absolute;
      left: to-rem(17px);
      top: to-rem(17px);
    }
  }

  input {
    width: 100%;
    height: to-rem(54px);
    border: 1px solid #C4C8D6;
    border-radius: 6px;
    padding-left: to-rem(55px);
    font-size: to-rem(16px);
  }

  input::placeholder {
    font-weight: 400;
    font-size: to-rem(16px);
    color: #676767;
  }

  .options_container {
    &:before {
      bottom: 103%;
    }

    &:after {
      bottom: 106%;
    }
  }

  .Selected_VM_list {
    margin-top: to-rem(30px);
    display: flex;
    flex-direction: column;
    row-gap: to-rem(20px);

    .List_head {
      display: flex;
      font-weight: 600;
      font-size: 12px;
      color: #444444;

      &>div {
        &:nth-child(1) {
          width: to-rem(445px);
        }

        &:nth-child(2) {
          width: to-rem(255px);
        }

        &:nth-child(3) {
          width: to-rem(220px);
        }

        &:nth-child(4) {
          width: to-rem(230px);
        }
      }
    }

    .List_body {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(11px);

      .List_item {
        padding: to-rem(8px) to-rem(20px);
        border: 1px solid #C4C8D6;
        border-radius: to-rem(6px);
        display: flex;
        align-items: center;
        width: 100%;

        &>div {
          &:nth-child(1) {
            width: to-rem(425px);
          }

          &:nth-child(2) {
            width: to-rem(255px);
            font-size: 15px;
            color: #676767;
          }

          &:nth-child(3) {
            width: to-rem(220px);
          }

          &:nth-child(4) {
            max-width: to-rem(220px);
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #676767;
            column-gap: to-rem(12px);
          }

          &:nth-child(5) {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.popup_menu {
  // background-color: red;
  padding: to-rem(21px) to-rem(21px) to-rem(21px) to-rem(21px);
  width: fit-content !important;

  div {
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: to-rem(7px);
  }
}