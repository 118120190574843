.AddNew {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px dashed #d3d6e2;
  border-radius: 3px;
  height: to-rem(69px);
  color: #8a8a8a;
  font-size: to-rem(16px);
  justify-content: flex-start;
  padding-left: to-rem(20px);

  @include minDesktopSmall {
    justify-content: center;
    padding-left: 0;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: to-rem(10px);
    width: to-rem(27px);
    height: to-rem(27px);
    color: #0069ff;
    font-size: to-rem(18px);
    border: 1px solid #d3d6e2;
    border-radius: to-rem(3px);
  }

  &:hover {
    @include minDesktopSmall {
      border: 1px solid #0069ff;
      color: #0069ff;
      font-weight: 500;

      span {
        border: 1px solid #0069ff;
      }
    }
  }
}
