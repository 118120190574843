.Layout {
  header {
    position: relative;
    text-align: center;

    img.logo {
      max-width: to-rem(285px);
      margin: to-rem(40px) 0 to-rem(40px);
      @include minDesktopSmall {
        margin: to-rem(110px) 0 to-rem(75px);
      }
    }
  }
  &.Login {
    header {
      .design-element {
        position: absolute;
        top: 0;
        right: 0;
        background: #180d5b;
        z-index: -1;
        width: 100%;
        height: to-rem(350px);

        @include minTablet {
          height: to-rem(450px);
          width: calc(50vw + #{to-rem(240px)});
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 99.9%;
          top: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 to-rem(536px) to-rem(383px) 0;
          border-color: transparent #180d5b transparent transparent;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          right: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: #180d5b transparent transparent transparent;
          border-width: to-rem(309px) 100vw 0 0;

          @include minTablet {
            border-width: to-rem(109px) calc(50vw - #{to-rem(100px)}) 0 0;
          }

          @include minDesktopSmall {
            border-width: to-rem(309px) calc(50vw - #{to-rem(240px)}) 0 0;
          }
        }
      }
    }
  }
  &.Register {
    header {
      .design-element {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        background: #180d5b;
        z-index: -1;
        height: to-rem(350px);
        @include minTablet {
          width: calc(50vw + #{to-rem(240px)});
          height: to-rem(550px);
        }

        @include minDesktopSmall {
          height: to-rem(450px);
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: #180d5b transparent transparent transparent;
          top: 100%;
          border-width: to-rem(383px) 100vw 0 0;
          @include minTablet {
            top: 0;
            left: 100%;
            border-width: to-rem(383px) to-rem(536px) 0 0;
          }
        }

        &:before {
          content: '';
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 320px 309px 0;
          @include minTablet {
            display: block;
            border-width: 0 calc(50vw - #{to-rem(240px)}) 109px 0;
          }
          @include minDesktopSmall {
            border-width: 0 calc(50vw - #{to-rem(240px)}) 309px 0;
          }
          border-color: transparent #180d5b transparent transparent;
        }
      }
    }
  }
  &.ForgotPassword,
  &.ResetPassword {
    header {
      .design-element {
        position: absolute;
        top: 0;
        left: 0;
        background: #180d5b;
        z-index: -1;
        width: 100%;
        height: to-rem(350px);

        @include minTablet {
          height: to-rem(500px);
          width: calc(50vw + #{to-rem(240px)});
        }

        @include minDesktopSmall {
          height: to-rem(300px);
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: #180d5b transparent transparent transparent;
          border-width: to-rem(323px) 100vw 0 0;
          top: 99.9%;
          left: 0;

          @include minTablet {
            left: 100%;
            top: 0;
            border-width: to-rem(323px) to-rem(536px) 0 0;
          }
        }

        &:before {
          content: '';
          display: none;
          position: absolute;
          top: 99.9%;
          left: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 320px 309px 0;
          @include minTablet {
            border-width: 0 calc(50vw - #{to-rem(240px)}) 79px 0;
            display: block;
          }
          @include minDesktopSmall {
            border-width: 0 calc(50vw - #{to-rem(240px)}) 279px 0;
          }
          border-color: transparent #180d5b transparent transparent;
        }
      }
    }
  }
}
