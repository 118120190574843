.ResetPasswordPage {
  .card {
    box-shadow: 4px 2px 10px rgba(0,0,0,0.2);;
  }
  .ill {
    img {
      position: absolute;
      display: none;

      @include minTablet {
        display: block;
      }
    }

    &__1 {
      left: calc(100% + 1.14286rem);
      bottom: 4.57143rem;
      max-width: 11rem;

      @include minDesktopSmall {
        left: calc(100% + #{to-rem(170px)});
        bottom: to-rem(64px);
        max-width: to-rem(168px);
      }
    }

    &__2 {
      left: calc(100% + 8.14286rem);
      top: 10rem;
      max-width: 7.57143rem;

      @include minDesktopSmall {
        left: calc(100% + #{to-rem(310px)});
        top: to-rem(140px);
        max-width: to-rem(106px);
      }
    }

    &__3 {
      top: 20.14286rem;
      left: -19.71429rem;
      max-width: 21rem;

      @include minDesktopSmall {
        top: to-rem(30px);
        left: to-rem(-640px);
        max-width: to-rem(490px);
      }
    }
  }
}
