$colors: (
  'white': (
    '1': #ffffff,
  ),
  'blue': (
    '1': #0069ff,
    '2': #031B4E,
  ),
  'grey': (
    '1': #676767,
    '2': #444,
    '3': #444444,
    '4': #d3d6e2,
    '5': #222222,
    '6': #414141,
    '7': #8a8a8a,
    '8': #999999,
    '9': #585757,
  ),
  'orange': (
    '1': #f9690e,
    '2': #fa7756,
  ),
  'red': (
    '1': #fa7756,
    '2': #ff2c00,
  ),
  'green': (
    '1': #17d175,
  ),
);

@mixin color-modifiers($attribute: 'color', $prefix: '-', $separator: '-') {
  @each $name, $color in $colors {
    &#{$prefix}#{$name} {
      @each $tone, $hex in $color {
        &#{$separator}#{$tone} {
          #{$attribute}: $hex;
        }
      }
    }
  }
}

.text {
  @include color-modifiers;
}

.bg {
  @include color-modifiers('background');
}
