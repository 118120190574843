.Sidebar {
  width: to-rem(214px);
  display: none;

  @include minTablet {
    display: block;
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    width: to-rem(214px);
    background: #031B4E;
  }

  img {
    max-width: to-rem(20px);
  }

  .logo {
    display: flex;
    justify-content: center;
    margin-top: to-rem(53px);
    img {
      max-width: to-rem(40px);
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding-top: to-rem(40px);

    .description {
      display: none;
    }

    .category {
      color: #ffffff;
      font-weight: 500;
      font-size: to-rem(12px);
      margin-left: to-rem(45px);
      margin-bottom: to-rem(20px);
    }

    ul {
      list-style: none;
      padding: 0;
      margin-bottom: to-rem(30px);

      a {
        display: flex;
        color: #ffffff;
        font-size: to-rem(15px);
        justify-content: flex-start;
        align-items: center;
        margin: 0 to-rem(21px) to-rem(12px) to-rem(21px);
        padding: to-rem(5px) to-rem(10px);
        transition: color 0.7s ease;

        @include minTablet {
          padding: to-rem(10px) to-rem(19px);
        }

        &.active {
          font-weight: 600;
          background: #102E6D;
          border-radius: 5px;
        }

        span {
          display: none;
        }

        &:hover {
          font-weight: 600;
          background: #102E6D;
          border-radius: 5px;
        }
      }
    }
  }

  .Switch {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: to-rem(40px);
    height: to-rem(20px);
    background: #e1e4ec;
    border-radius: to-rem(9px);
    margin: to-rem(70px) auto to-rem(66px);

    &__status {
      height: to-rem(4.5px);
      width: to-rem(24px);
      background: #fff;
      border-radius: to-rem(9px);
      display: flex;
      align-items: center;
      position: absolute;

      .round {
        display: block;
        width: to-rem(12px);
        height: to-rem(12px);
        border-radius: 50%;
        // background: #0069FF;
        transition: all 0.3s ease-in;
        position: absolute;
        left: to-rem(-3px);
        background: {
          image: radial-gradient(circle closest-side, #0069ff, #0069ff);
        }
      }
    }
  }

  &__extended {
    width: to-rem(214px);
    nav ul a {
      img {
        margin-right: to-rem(10px);
      }
      span {
        display: block;
      }
    }
    .Switch {
      &__status {
        .round {
          left: calc(100% - #{to-rem(9px)});
          animation: {
            name: switchReverseEffect;
            duration: 0.4s;
            timing-function: ease;
          }
        }
      }
    }
  }
  &__compact {
    .Switch {
      &__status {
        .round {
          animation: {
            name: switchEffect;
            duration: 0.4s;
            timing-function: ease;
          }
        }
      }
    }
  }
}

@keyframes jump {
  0% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes switchEffect {
  0% {
    transform: translate(20px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes switchReverseEffect {
  0% {
    transform: translate(-20px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
