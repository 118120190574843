.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: to-rem(6px);
  width: 100%;
  font-weight: 500;
}

.pagination-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #444444;
  height: 40px;
  width: 30px;
  text-decoration: none;
  transition: background-color .2s;
  cursor: pointer;  
  font-size: to-rem(20px);
}

.pagination-container a.active {
  background-color: #0069FF;
  color: white;
  border-radius: to-rem(6px);
  width: 40px !important;
}

.pagination-container a.disabled { 
  opacity: 0.2;
  color: #444444 !important;
}
.arrow_buttons {
  color:  #0069FF !important;
  width: unset !important;
  font-size: to-rem(17px) !important;
  margin: 0 to-rem(14px);
}
.dots {
  width: unset !important;
}
