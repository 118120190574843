.ResetPasswordPage,
.ForgotPasswordPage,
.LoginPage,
.RegisterPage {
  h2 {
    text-align: center;
    color: #142149;
    font-size: to-rem(41px);
    font-weight: bold;
    margin: 0 0 to-rem(20px);
  }

  p {
    font-size: to-rem(16px);
    line-height: 1.6;
  }

  label {
    flex: 1;
    font-size: to-rem(16px);
    color: #676767;
    font-weight: 400;
  }

  a {
    font-size: to-rem(16px);
  }

  form {
    .Field {
      &:nth-child(1) {
        .ico {
          max-width: to-rem(22px);
        }
      }
      &:nth-child(2) {
        .ico {
          max-width: to-rem(18px);
        }
      }
    }
  }

  .ill {
    img {
      position: absolute;
    }

    &__1 {
      left: calc(100% + #{to-rem(170px)});
      bottom: to-rem(64px);
      max-width: to-rem(168px);
    }

    &__2 {
      left: calc(100% + #{to-rem(310px)});
      top: to-rem(140px);
      max-width: to-rem(106px);
    }

    &__3 {
      top: to-rem(30px);
      left: to-rem(-640px);
      max-width: to-rem(490px);
    }
  }

  .card {
    position: relative;
  }

  input {
    &[type='checkbox'] {
      margin: 0;
      margin-right: to-rem(7px);
      max-width: to-rem(20px);
    }
  }

  .recaptcha {
    transform: scale(0.7);
    position: relative;
    margin-left: to-rem(-73px);
    @media screen and (max-width: 370px) {
      margin-left: to-rem(-60px);
    }
  }

  .Field {
    margin-bottom: to-rem(29px);
  }
}
