.Footer {
  margin: to-rem(40px) 0 to-rem(15px);

  .container > .flex {
    flex-direction: column;
    @include minTablet {
      flex-direction: row;
    }
    > div {
      flex: 1;
      padding: to-rem(10px) 0;
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex: 1;
    padding: 0;
    @include minTablet {
      justify-content: flex-end;
    }
    li {
      margin: 0 to-rem(10px);
    }

    a {
      font-weight: 700;
      @extend .text-grey-1;
    }
  }
}
