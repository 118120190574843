.LoadBalancers {
  .Create_lb_button {
    width: to-rem(226px);
    height: to-rem(52px);
    font-size: to-rem(15px);
    font-weight: 600;
  }

  .LB_list {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(20px);

    .List_header {
      display: flex;
      color: #444444;
      font-size: to-rem(12px);
      font-weight: 600;

      &>div {
        &:nth-child(1) {
          width: to-rem(310px);
        }

        &:nth-child(2) {
          width: to-rem(180px);
        }

        &:nth-child(3) {
          width: to-rem(170px);
        }

        &:nth-child(4) {
          width: to-rem(180px);
        }
      }
    }

    .List_body {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(11px);
      width: 100%;

      .List_item {
        border: 1px solid #C4C8D6;
        border-radius: to-rem(6px);
        display: flex;
        padding: to-rem(13px) to-rem(20px);
        width: 100%;

        &:hover {
          border-color: #0069FF;
        }

        .Main_content {
          display: flex;
          align-items: center;
          cursor: pointer;

          &>div {
            &:nth-child(1) {
              width: to-rem(290px);
            }

            &:nth-child(2) {
              width: to-rem(180px);
            }

            &:nth-child(3) {
              width: to-rem(170px);
            }

            &:nth-child(4) {
              width: to-rem(180px);
            }

            &:nth-child(5) {
              width: to-rem(300px);
            }
          }

          .more {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
          }
        }

      }
    }
  }

  .Prot_Alg_Container {

    .Prot_Alg_Content {
      max-width: max-content;
      border: 1px solid #d3d6e2;
      background: #F8F8F8;
      border-radius: to-rem(6px);
      font-size: to-rem(15px);
      font-weight: 600;
      padding: to-rem(8px) to-rem(20px);
      color: #676767;
    }
  }
}

.ForwardRules {
  .add_rule_button {
    width: to-rem(180px);
    height: to-rem(52px);
    font-size: to-rem(15px);
    font-weight: 600;
  }

  .rules {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(20px);

    .rules_header {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #444444;

      &>span {
        &:nth-child(1) {
          margin-left: to-rem(10px);
          width: to-rem(250px);
        }

        &:nth-child(2) {
          width: to-rem(210px);
        }

        &:nth-child(3) {
          width: to-rem(230px);
        }

        &:nth-child(4) {
          width: to-rem(180px);
        }
      }
    }

    .more {
      font-weight: 600;
      font-size: 16px;
      color: #0069FF;
    }

    .List_item {
      background: #FFFFFF;
      border: 1px solid #C4C8D6;
      border-radius: to-rem(6px);

      .List_item_inputs {
        display: flex;
        padding: to-rem(16px) to-rem(20px);

        .popup_menu {
          z-index: 2;
        }
      }

      .List_item_show {
        display: flex;
        padding: to-rem(16px) to-rem(30px);

        .Main_content {
          font-weight: 600;
          font-size: to-rem(16px);
          color: #676767;
        }

        .popup_menu {
          z-index: 2;
        }
      }
    }

    .Main_content {
      display: flex;

      &>div {
        &:nth-child(1) {
          width: to-rem(229px);
        }

        &:nth-child(2) {
          width: to-rem(210px);
        }

        &:nth-child(3) {
          width: to-rem(230px);
        }

        &:nth-child(4) {
          width: to-rem(180px);
        }

        &:nth-child(5) {
          width: to-rem(254px);
        }
      }
    }
  }

  .Instance_items_container {
    width: to-rem(1170px);

    .remove_button {
      width: to-rem(148px);
      height: to-rem(48px);
    }

    .Instance_item {
      width: 100%;

      &>div {
        &:nth-child(1) {
          width: to-rem(345px);
        }

        &:nth-child(2) {
          width: to-rem(220px);
        }

        &:nth-child(3) {
          width: to-rem(160px);
        }

        &:nth-child(4) {
          width: to-rem(350px);
        }

        &:nth-child(5) {
          max-width: 400px;
          // flex-grow: 1;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }


  .Name_input {
    width: to-rem(198px);
    height: to-rem(48px);
    padding: 0 to-rem(20px);
    border: 1px solid #C4C8D6;
    border-radius: 6px;
  }

  .Port_input {
    width: to-rem(120px);
    height: to-rem(48px);
    padding: 0 to-rem(20px);
    border: 1px solid #C4C8D6;
    border-radius: 6px;
  }

  .Port_item {
    font-weight: 600;
    font-size: 16px;
    color: #676767;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .Value_select {
    width: to-rem(190px);
    height: to-rem(48px);
    margin: unset !important;

    .Selected {
      width: to-rem(190px);
      height: to-rem(48px);
    }
  }

  .InstanceSelect {
    .Search__results {
      width: 550px;
      top: calc(100% + 1rem);
    }
  }
}

.Disabled_IP {
  display: flex;
  align-items: center;
  width: 100%;
  height: to-rem(58px);
  padding: 0 30px;
  column-gap: to-rem(10px);
  border: 1px solid #C4C8D6;
  border-radius: 6px;
  color: #676767;
  font-size: to-rem(15px);
  font-weight: 600;
  cursor: pointer;
}
