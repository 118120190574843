.InfoBox {
  position: relative;
  display: inline-flex;
  line-height: 1.4;

  &__ico {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-size: to-rem(12px);
    font-weight: bold;
    color: #fff;
    background: #0069ff;
    height: to-rem(19px);
    width: to-rem(19px);
    border-radius: 50%;

    &--small {
      width: to-rem(13px);
      height: to-rem(13px);
      font-size: to-rem(10px);
    }
  }

  &__container {
    padding: to-rem(10px);
    border-radius: to-rem(6px);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: to-rem(200px);
    z-index: 3;
    position: absolute;
    left: 50%;
    bottom: calc(100% + 10px);
    transform: translateX(-50%);
    pointer-events: none;
  }

  &__content {
    opacity: 0;
    transition: all 0.6s ease;
    position: relative;
    text-align: center;
    padding: to-rem(13px) to-rem(18px);
    border-radius: to-rem(3px);
    background: #031B4E;
    color: #fff;
    pointer-events: none;
    font-size: to-rem(16px);
    line-height: 1.6;
    @include minDesktopSmall {
      font-size: to-rem(12px);
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: to-rem(7px) to-rem(7px) 0 to-rem(7px);
      border-color: #031B4E transparent transparent transparent;
    }
  }

  &__size {
    &--small {
      min-width: to-rem(100px);
    }
  }

  &__color {
    &--green {
      background: #17d175;
      &:after {
        border-color: #17d175 transparent transparent transparent;
      }
    }
    &--red {
      background: #ff2c00;
      &:after {
        border-color: #ff2c00 transparent transparent transparent;
      }
    }
    &--yellow {
      background: goldenrod;
      &:after {
        border-color: goldenrod transparent transparent transparent;
      }
    }

    &--white {
      background: #fff;
      border: 1px solid #c4c8d6;
      color: #676767;
      &:after {
        border-color: #fff transparent transparent transparent;
      }
      &:before {
        content: '';
        position: absolute;
        display: block;
        top: calc(100% + 1px);
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: to-rem(7px) to-rem(7px) 0 to-rem(7px);
        border-color: #c4c8d6 transparent transparent transparent;
      }
    }
  }

  &:hover {
    .InfoBox {
      &__content {
        opacity: 1;
      }
    }
  }
}
