.Checkbox {
  .checkbox {
    --background: #fff;
    --border: #d1d6ee;
    --border-hover: #bbc1e1;
    --border-active: #0069ff;
    --tick: #fff;
    position: relative;
    display: flex;

    input,
    svg {
      width: to-rem(21px);
      height: to-rem(21px);
      display: block;
    }
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      position: relative;
      outline: none;
      background: var(--background);
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      border-radius: 4px;
      transition: box-shadow 0.3s;
      box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
      &:hover {
        --s: 2px;
        --b: var(--border-hover);
      }
      &:checked {
        --b: var(--border-active);
      }

      &:disabled {
        background: rgba(211, 214, 226, 0.22);
      }
    }
    svg {
      pointer-events: none;
      fill: none;
      stroke-width: to-rem(2px);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--stroke, var(--border-active));
      position: absolute;
      top: 0;
      left: 0;
      width: to-rem(21px);
      height: to-rem(21px);
      transform: scale(var(--scale, 1)) translateZ(0);
    }
    &.path {
      input {
        &:checked {
          --s: #{to-rem(2px)};
          transition-delay: 0.4s;
          & + svg {
            --a: 16.1 86.12;
            --o: 102.22;
          }
        }
      }
      svg {
        stroke-dasharray: var(--a, 86.12);
        stroke-dashoffset: var(--o, 86.12);
        transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
      }
    }
    &.bounce {
      --stroke: var(--tick);
      input {
        &:checked {
          --s: 11px;
          & + svg {
            animation: bounce 0.4s linear forwards 0.2s;
          }
        }
      }
      svg {
        --scale: 0;
      }
    }
  }

  &--error {
    .checkbox {
      border-color: red;

      input {
        --border: red;
      }
    }
  }
  &--full-box {
    .checkbox {
      &.path {
        width: to-rem(21px);
        height: to-rem(21px);
        position: relative;
        display: block;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          height: to-rem(0px);
          width: to-rem(0px);
        }
      }
      input {
        &:checked {
          --s: 1px;
          --b: var(--border);

          & + svg {
            background: #0069ff;
            height: to-rem(9px);
            width: to-rem(9px);
            border-radius: to-rem(2px);
            animation: boxBounce 0.5s ease;
          }
        }
      }
    }
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes boxBounce {
  0% {
    width: 40%;
    height: 40%;
  }
  20% {
    width: 20%;
    height: 20%;
  }
  40% {
    width: 60%;
    height: 60%;
  }
  60% {
    width: 20%;
    height: 20%;
  }
  80% {
    width: 60%;
    height: 60%;
  }
  100% {
    width: 40%;
    height: 40%;
  }
}
