.SshModal {
  .Modal {
    &__content {
      padding: to-rem(30px);
      max-width: to-rem(1240px);
      top: 0;
      transform: translateX(-50%);
      @include minTablet {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    & .Field {
      &__selected {
        height: to-rem(58px);
        display: flex;
        align-items: center;
      }
    }
  }
  p {
    font-size: to-rem(16px);
    color: #676767;
    font-weight: 400;
    line-height: 1.6;
  }

  h2 {
    color: #0069FF;
    font-weight: 400;
    font-size: to-rem(27px);
    margin-bottom: to-rem(10px);
  }

  h3 {
    font-weight: 700;
    font-size: to-rem(16px);
    color: #676767;
  }

  .grey-info {
    background: #0069FF;
    color: #fff;
  }

  &__details {
    padding-top: to-rem(5px);
    margin-top: 20px;
    overflow-x: visible;
    overflow-y: auto;

    @include minTablet {
      width: 100%;
      max-width: to-rem(460px);
      margin-top: 0;
      padding-left: to-rem(50px);
      overflow: auto;
      max-height: to-rem(520px);
      position: relative;

      &:before {
        content: "";
        display: block;
        height: 1px;
        width: to-rem(488px);
        position: absolute;
        top: to-rem(30px);
        left: calc(-50% - #{to-rem(13px)});
        transform: rotate(-90deg) translateX(-50%);
        background: linear-gradient(90deg, rgba(196, 200, 214, 0) 3.82%, #C4C8D6 52.16%, rgba(196, 200, 214, 0) 96.59%);
      }
    }
  }

  .hidden {
    display: none;
    @include minTablet {
      display: block;
    }
    @include minDesktopSmall {
      display: none;
    }
  }

  .visible {
    display: flex;
    @include minTablet {
      display: none;
    }
    @include minDesktopSmall {
      display: flex;
    }
  }

  .Field__content {
    position: relative;
    &:after {
      content: "*";
      display: block;
      color: #0069ff;
      font-weight: 600;
      font-family: arial;
      font-size: 24px;
      position: absolute;
      right: to-rem(15px);
      top: calc(50% - 8px);
    }
  }

  .keyContent {
    .Field__content {
      &:after {
        top: to-rem(15px);
      }
    }
  }

  .arrow {
    cursor: pointer;
    height: 10px;
    width: 10px;
  }
}
