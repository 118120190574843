.IncreaseModal {
  .pricing-box {
    .Card {
      &__body {
        padding-top: to-rem(10px);
        padding-bottom: to-rem(10px);
      }
    }

    &__price-per-hour {
      &:after {
        display: none;
      }

      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.list-head {
  background: white;
  padding-left: 0;
  padding-right: 0;
  border: none;
}

.list-body {
  display: flex;
  flex-direction: column;
  row-gap: to-rem(11px);

  .list-item {
    border: 1px solid #C4C8D6 !important;
    border-radius: 6px;
    padding-left: to-rem(10px);
    padding-right: to-rem(10px);
  }
}

.content-center {
  margin-top: 15%;
}

.more_container {
  .popup {
    padding: to-rem(14px) to-rem(21px);
    width: to-rem(170px) !important;
    transition: all 0.5s ease;

    div {
      padding: to-rem(7.5px) 0;
      display: flex;
      align-items: center;
      column-gap: to-rem(7px);
    }
  }
}

.VolumesPage {
  .menu-item:hover {
    text-decoration: none;
  }
  .table__head>div {
    font-size: to-rem(12px);
  }
  .table__row {
    min-height: to-rem(64px);
  }

  .no-instance-state {
    .image {
      width: to-rem(42px);
      display: flex;
      justify-content: center;

      img {
        width: to-rem(23px);
      }
    }
  }
}
