$color_1: #cbcbf4;
$color_2: #f9c9c2;
$color_3: #ffe0c7;

$color_1_dark: #6c69b0;
$color_1_darker: #585595;
$color_1_darkest: #292953;
$color_1_light: #ffffff;
$color_1_dim: #e8e8f3;

$title_color_dark: #3e444e;
$subtitle_color_dark: #7d87a1;

$footer_title_color: #ffe0c7;
$copyright_color: #a7aecd;
$description_color: #7b85a0;

$component_width: 1100px;
$header_width: 1300px;

$font_1: "Roboto", sans-serif;
$line_height: 1.8;

$minMobileMdWidth: 360px;
$minTabletWidth: 768px;
$minDesktopSmallWidth: 1024px;
$minDesktopLargeWidth: 1366px;
$minDesktopWideWidth: 1600px;
$minDesktopExtraWideWidth: 1921px;

$maxMobileSmWidth: 359.98px;
$maxMobileWidth: 767.98px;
$maxTabletWidth: 1023.98px;
$maxDesktopSmallWidth: 1365.98px;
$maxDesktopLargeWidth: 1599.98px;
