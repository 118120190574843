.CustomSelect {
  position: relative;
  border: 1px solid #d3d6e2;
  height: to-rem(58px);
  display: flex;
  align-items: center;
  border-radius: to-rem(6px);

  .Field__content {
    position: inherit;
    overflow: visible;
    height: 100%;
    & .disabled {
      pointer-events: none;
    }
  }

  select {
    padding: 0 to-rem(20px) 0;
    position: absolute;
    border: none;
    outline: none;
    font-size: to-rem(16px);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  input {
    border: none;
  }
}

.Select {
  width: 100%;
  .Field {
    &__content {
      width: 100%;
      position: relative;
      display: flex;
      overflow: visible;
      justify-content: center;
    }

    &__selected {
      display: flex;
      width: 100%;
      padding: to-rem(10px) to-rem(20px);
      border-radius: to-rem(6px);
      height: to-rem(50px);

      .Field__content {
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  select {
    pointer-events: none;
    appearance: none;
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border: 1px solid #d3d6e2;
    padding: 0;
    list-style: none;
    border-radius: to-rem(3px);
    max-height: to-rem(240px);
    overflow: hidden;
    overflow-y: auto;
    z-index: 10;

    li {
      border-bottom: 1px solid #d3d6e2;
      padding: to-rem(10px) to-rem(20px);
      height: to-rem(50px);
      cursor: pointer;

      &.active {
        background: #f8f8f8;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.Instance__picked {
  .Instance__title-ico {
    margin: 0;
    width: to-rem(84px);
  }

  .Instance__status {
    right: to-rem(84px) !important;
    bottom: to-rem(16.8px) !important;
  }
}
