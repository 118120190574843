.CreateFirewall {
  .rules {
    &__head {
      display: grid;
      grid-template-columns: repeat(4, 1fr) to-rem(100px);
      gap: 10px;
    }

    &__body .existingRules {
      padding: to-rem(10px) to-rem(15px);
      grid-template-columns: repeat(4, 1fr) to-rem(100px);
      gap: 10px;
    }
    .button {
      &__add {
        height: to-rem(58px);
        width: to-rem(100px);
      }
    }
    &__create {
      max-width: to-rem(200px);
      padding: to-rem(10px) to-rem(15px);
    }
    .Select {
      &__ip-rule {
        height: to-rem(58px);
        .Field__content {
          font-size: to-rem(16px);
          cursor: pointer;
          height: 100%;
          color: #444444;
        }
      }
    }
    &--outbound {
      .rules {
        &__head {
          display: grid;
          grid-template-columns: repeat(6, 1fr) to-rem(100px);
          gap: 10px;
        }
        &__body .existingRules {
          padding: to-rem(10px) to-rem(15px);
          grid-template-columns: repeat(6, 1fr) to-rem(100px);
          gap: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .CreateFirewall {
    .rules {
      &__wrapper {
        display: grid;
        grid-template-columns: 1fr 2fr;
      }
      &__head {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, to-rem(50px)) to-rem(100px);
        gap: to-rem(40px);
        background: none;
        & > div {
          display: flex;
          align-items: center;
        }
      }
      &__body .existingRules {
        padding: to-rem(10px) to-rem(15px);
        grid-template-columns: 1fr;
        display: grid;
        grid-template-rows: repeat(4, to-rem(50px)) to-rem(100px);
        gap: to-rem(40px);
      }
      &__create {
        display: none;
      }
      &--outbound {
        .rules {
          &__head {
            grid-template-rows: repeat(6, to-rem(50px)) to-rem(100px);
            grid-template-columns: 1fr;
          }
          &__body .existingRules {
            grid-template-rows: repeat(6, to-rem(50px)) to-rem(100px);
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}
