.Header {
  min-height: to-rem(52px);
  display: flex;
  position: relative;
  z-index: 2;
  margin-top: to-rem(42px);

  .credit {
    border: 1px solid #C4C8D6;
    border-radius: 5px;
    padding: to-rem(5px);
    min-width: to-rem(80px);

    &:hover {
      border-color: #0069ff;
    }
  }

  .logo {
    width: to-rem(75px);
    margin-right: to-rem(8px);
    border-radius: 6px;
    justify-content: center;
    background: #0069ff;
    img {
      max-width: to-rem(40px);
    }
    @include maxMobile {
      display: flex;
    }
  }
  .menu {
    width: 20px;
    height: 16px;
    border-top: 2px solid #0069ff;
    border-bottom: 2px solid #0069ff;
    position: relative;
    margin: 0 to-rem(15px) 0 to-rem(5px);
    @include maxMobile {
      display: inline-flex;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      width: 20px;
      height: 2px;
      background: #0069ff;
    }
  }

  .Nav {
    position: relative;
    img {
      width: to-rem(31px);
    }

    .hover-parent {
      .hover {
        display: none;
      }
      &:hover, &.active {
        > .hover {
          display: flex;
        }
        > .not-hover {
          display: none;
        }
      }
    }

    .user {
      display: flex;
      align-items: center;

      .hover-parent {
        pointer-events: none;
        &:after {
          content: "";
          display: inline-block;
          width: to-rem(9px);
          height: to-rem(9px);
          border-top: 2px solid #0069ff;
          border-right: 2px solid #0069ff;
          transform: rotate(135deg);
          margin-left: to-rem(8px);
          position: relative;
          z-index: -1;
        }

        &:hover, &.active {
          &:after {
            border-color: #031B4E;
          }
        }
      }
      &.m-r-50 {
        margin-right: 0 !important;

        @include minTablet {
          margin-right: to-rem(50px) !important;
        }
      }
    }

    .notification {
      position: relative;
      margin-top: to-rem(5px);

      img {
        max-width: to-rem(24px);
      }

      span {
        display: flex;
        width: to-rem(15px);
        height: to-rem(15px);
        border: to-rem(2px) solid #0069ff;
        font-size: to-rem(8px);
        position: absolute;
        right: to-rem(-5px);
        top: to-rem(-5px);
        font-weight: 700;
        color: #0069ff;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }
    }

    .button {
      display: flex;
      height: to-rem(52px);
      border-radius: 0 6px 6px 0;
      font-size: to-rem(16px);
      font-weight: 600;

      img {
        width: to-rem(17px);
        margin-right: to-rem(7px);
      }
    }

    &__Create {
      .Nav__Dropdown {
        right: calc(100% - #{to-rem(112px)})
      }
    }
    &__Dropdown {
      position: absolute;
      top: calc(100% + #{to-rem(30px)});
      right: 0;

      .Card__body {
        padding: 0;

        > div {
          &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 9px 13px 9px;
            border-color: transparent transparent #c4c8d6 transparent;
            position: absolute;
            bottom: 100%;
            right: 20px;
          }
          &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 8px 13px 8px;
            border-color: transparent transparent #ffffff transparent;
            position: absolute;
            bottom: calc(100% - 1px);
            right: 21px;
          }
        }

        &:hover {
          > div {
            &:before {
              border-color: transparent transparent #0069ff transparent;
            }
          }
        }
      }

      ul {
        padding: 0;
        list-style: none;
        font-size: 12px;

        li {
          padding: to-rem(8px) to-rem(7px);
          border-radius: to-rem(5px);

          &:hover {
            background: #F5F6F7;
            span {
              color: #0069FF
            }
          }
          a {
            display: flex;
            align-items: center;
            font-size: to-em(17px);
            color: #444;
            text-align: left;

            img {
              margin-right: 10px;
            }

            span {
              margin: 0;
              line-height: 1.2;
              font-size: to-rem(13px);
              font-weight: 600;
            }
            .description {
              font-size: to-em(12px);
              font-weight: 500;
              color: #676767;
            }

            div {
              display: flex;
              justify-content: center;
              flex-direction: column;
            }
          }
          .Card__body {
            &:hover {
              border-color: #d3d6e2;
            }
          }
        }
      }
      .MobileNav {
        width: 100%;
        min-width: 240px;

        .Switch {
          display: none;
        }

        .notification {
          img {
            margin-right: 0;
          }

          span {
            text-align: center;
          }
        }

        .profile {
          padding: to-rem(20px) 0 to-rem(10px);
          margin: to-rem(20px) 0;
          border-top: 1px solid #d3d6e2;
          border-bottom: 1px solid #d3d6e2;

          * {
            word-break: break-word;
          }
        }
      }

      .border-bottom {
        border-bottom: 1px solid #d3d6e2;
      }

      .ProfileDropdown {
        width: 100%;
        max-width: 245px;
        padding: to-rem(15px) to-rem(20px);

        img {
          max-width: to-rem(25px);
        }

        * {
          word-break: break-word;
        }

        .line {
          width: 100%;
          max-width: 100%;
        }

        ul {
          margin: to-rem(10px) 0;

          li {
            margin-bottom: to-rem(5px);
          }
        }

        .profile {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .CreateDropdown {
        padding: to-rem(6px) to-rem(17px);
        min-width: to-rem(280px);
        &:before {
          right: to-rem(85px) !important;
        }
        &:after {
          right: to-rem(86px) !important;
        }
        @include minTablet {
          &:before {
            left: auto !important;
            right: to-rem(39px) !important;
          }
          &:after {
            left: auto !important;
            right: to-rem(40px)!important;
          }
        }
        li {
          position: relative;
          .line {
            height: 3px;
            position: absolute;
            bottom: to-rem(-12px);
            left: 0;
            width: 100%;
            max-width: 100%
          }
        }
      }

      .Card__body {
        .Notifications {
          padding: 0 to-rem(30px);
          padding-bottom: to-rem(40px);
          min-width: to-rem(440px);
          max-width: inherit !important;

          @include minTablet {
            min-width: to-rem(753px);
            &:before {
              right: to-rem(180px);
            }
            &:after {
              right: to-rem(181px);
            }
          }

          &__heading {
            span {
              color: #676767;
              font-size: 16px;
              font-weight: 600;
            }
            .button {
              height: 32px;
              background: #F8F8F8;
              border: 1px solid #0069FF;
              border-radius: 5px;
              color: #0069FF;
              font-size: 14px;
            }
          }

          &__content {
            .status {
              max-height: to-rem(30px);
              &::after {
                margin-left: to-rem(15px);
                content: "";
                display: none;
                border-radius: 50%;
                width: to-rem(7px);
                height: to-rem(7px);
                background: #c4c8d6;
              }
              &--new {
                &::after {
                  background: #17d175;
                }
              }
              &--pending {
                &::after {
                  background: #FFA511;
                }
              }
              &--closed {
                &::after {
                  background: #999999;
                }
              }
            }

            > .flex {
              justify-content: space-between;
              flex-wrap: wrap;
              padding: to-rem(15px) 0;
              position: relative;

              &:last-child {
                border-bottom: none;
              }

              &:hover {
                background: #F5F6F7;
                border-radius: 5px;
              }

              .line {
                position: absolute;
                bottom: 0;
                width: 100%;
              }

              .markAsRead {
                color: #0069ff;
                font-style: italic;
                font-weight: 300;
              }

              > div {
                padding: 0 to-rem(15px);
                font-size: to-rem(16px);
                text-align: left;
                color: #8a8a8a;

                &.status {
                  padding: 0;
                }

                &.text-blue-1 {
                  color: #0069ff;
                }

                img {
                  max-width: to-rem(26px);
                  margin-right: to-rem(7px);
                }

                &:nth-child(4) {
                  width: 100%;
                  max-width: 100%;
                  margin-top: to-rem(10px);
                }

                @include minTablet {
                  flex: 1 1;

                  &:nth-child(1) {
                    max-width: to-rem(30px);
                  }
                  &:nth-child(3) {
                    max-width: to-rem(150px);
                  }
                  &:nth-child(4) {
                    margin-top: 0;
                    text-align: right;
                    max-width: to-rem(120px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


.Search {
  display: flex;
  flex: 1;
  position: relative;
  z-index: 1;
  input,
  form {
    display: flex;
    flex: 1;
    border: none;
    color: #8a8a8a;
    font-size: to-rem(15px);
    font-weight: 300;
    top: 50%;

    &:focus {
      outline: none;
    }
  }
  input {
    min-height: to-rem(52px);
    border: 1px solid #C4C8D6;
    border-radius: 6px 0 0 6px;
    padding-left: to-rem(50px);
    background: #FFFFFF;
    z-index: 2;
  }
  .placeholder {
    pointer-events: none;
    position: absolute;
    left: to-rem(20px);
    display: flex;
    align-items: center;
    color: #8a8a8a;
    font-size: to-rem(15px);
    font-weight: 300;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    span {
      color: #0069ff;
    }

    img {
      margin-right: to-rem(10px);
      max-width: to-rem(24px);
    }
  }

  &__overley {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;

    background: #031B4E;
    opacity: 0.5;
  }

  &__results {
    position: absolute;
    width: calc(100% + #{to-rem(111px)});
    top: calc(100% + #{to-rem(28px)});
    background: #FFFFFF;
    border: 1px solid #C4C8D6;
    border-radius: 6px;
    padding: to-rem(22px) to-rem(18px);

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 10px 8px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      bottom: calc(100% - 1px);
      left: 21px;
    }

    &::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 10px 9px;
      border-color: transparent transparent #c4c8d6 transparent;
      position: absolute;
      bottom: 100%;
      left: 20px;
    }

    &.Dashboard {
      .ResourcesTab ul {
        margin: 0;
        .name {
          @include minTablet() {
            flex-direction: row;
            align-items: center;

            a {
              margin-bottom: 0;
            }
          }
        }
        .status {
          left: to-rem(-5px);
          top: calc(50% - 4px);
        }
        li {
          border: none;
          padding: to-rem(6px) 0;
          padding-left: to-rem(15px);
          margin-bottom: 0;

          &:hover {
            background: #F5F6F7;
            border-radius: 5px;
          }
        }
      }
    }
  }

  &__instance {
    &:last-child {
      .line {
        display: none;
      }
    }

    .line {
      width: 100%;
      margin: to-rem(8px) 0;
      height: 1px;
      background: linear-gradient(90deg, rgba(196, 200, 214, 0) 3.82%, #C4C8D6 52.16%, rgba(196, 200, 214, 0) 96.59%);
    }
  }

  &--full-width {
    .Search__results {
      width: 100%;
      padding: to-rem(10px)
    }
  }
}
