.ProfilePage,
.SecurityPage,
.ReferralsPage {
  overflow: hidden;

  &__share {
    .flex-mobile-column > .flex {
      @include maxMobile {
        justify-content: space-between;
      }
    }

    button {
      align-self: flex-end;
    }
  }

  &__info-box {
    border: 1px solid #C4C8D6;
    border-radius: 6px;
    padding: to-rem(30px);
    margin-bottom: to-rem(30px);
    justify-content: center;
    align-items: center;

    * {
      word-break: break-word;
    }

    @include minTablet {
      align-items: flex-end;
      justify-content: space-between;
    }

    img {
      max-width: to-rem(139px);
      height: to-rem(139px);
      margin: to-rem(10px) auto to-rem(10px) auto;
      margin-right: to-rem(20px);
      @include minTablet {
        margin: 0;
        margin-right: to-rem(20px);
      }
    }

    h3 {
      font-size: to-rem(22px);
      color: #444444;
      font-weight: 600;
      margin: 0;
    }

    button {
      margin-top: to-rem(20px);
      @include minTablet {
        margin-top: 0;
      }
    }

    .box-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .server-limit {
      color: #676767;
      font-size: to-rem(16px);
      font-weight: 400;
      margin: 0;

      span {
        color: #0069ff;
      }
    }
  }

  .ProfilePage {
    .profile-box-bottom {
      @include maxMobile {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .button {
          margin-top: 0;
        }
      }
    }
    .EditProfileForm {
      width: 100%;
      .form {
        display: flex;
        flex-wrap: wrap;
        margin: 0 to-rem(-15px);
        > div {
          padding: 0 to-rem(15px);
          width: 50%;
        }
      }
      .checked-ico {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        max-width: to-rem(25px);
      }
    }
  }

  &__row {
    border-bottom: 1px solid #d3d6e2;
    margin-bottom: to-rem(30px);

    &:last-child {
      border-bottom: none;
    }

    h3 {
      color: #444444;
      font-weight: 600;
      font-size: to-rem(27px);
      margin-bottom: to-rem(15px);
    }

    .flex {
      align-items: center;
    }

    p {
      margin: 0;
      color: #676767;
      font-size: to-rem(16px);
      font-weight: 400;
    }

    .text-blue-1 {
      @extend .text-blue-1;
    }

    input {
      border: none;
    }

    .button {
      margin: 0 to-rem(25px) to-rem(25px) 0;
      @include minTablet {
        margin: 0 to-rem(25px) to-rem(25px) 0;
      }
    }
  }
}

.ReferralsPage {
  .Field input,
  h3 {
    font-weight: 400;
  }
}

.ProfilePage {
  .date {
    color: #676767;
    font-size: to-rem(16px);
    font-weight: 400;
    margin: to-rem(5px) 0;

    span {
      color: #0069ff;
    }
  }
}

.SecurityPage {
  overflow: visible;
  .line {
    background: linear-gradient(90deg, rgba(196, 200, 214, 0) 3.82%, #C4C8D6 52.16%, rgba(196, 200, 214, 0) 96.59%);
    width: 100%;
    height: 1px;
    margin: to-rem(25px) 0 to-rem(30px);
  }
  .no-ssh {
    border: 1px solid #C4C8D6;
    border-top: none;
    padding: to-rem(15px) to-rem(30px);
    text-align: center;
    font-size: to-rem(16px);
    color: #676767;;
  }
  &__row {
    .button {
      margin-right: 0;
      white-space: nowrap;
    }
  }
  &__info-box {
    .flex {
      align-items: flex-start;
    }
    h3 {
      color: #444444;
      font-size: to-rem(27px);
      margin-bottom: to-rem(5px);
      font-weight: 400;
    }
    p {
      font-size: to-rem(16px);
      margin-top: to-rem(5px);
      margin-bottom: to-rem(25px);
    }
    img {
      max-width: to-rem(50px);
      height: auto;
      margin-left: to-rem(20px);
      margin-right: to-rem(40px);
      margin-top: to-rem(5px);
      @include minTablet {
        margin-left: to-rem(30px);
        margin-right: to-rem(60px);
        margin-top: to-rem(5px);
      }
    }
    .flex-align-self-flex-end {
      align-self: flex-start;

      @include minTablet {
        align-self: flex-end;
      }
    }
  }

  .more {
    position: relative;
    @include minTablet {
      margin-right: to-rem(45px);
    }
    @include minDesktopSmall {
      margin-right: 0;
    }
  }

  .border-bottom {
    border-bottom: 1px solid #d3d6e2;
  }

  &__sshkey {
    .button {
      @include maxMobile {
        align-self: flex-end;
      }
    }
  }

  &__deactivate {
    .OrizontalScroll__scrollable {
      margin-right: to-rem(-20px);
      max-width: inherit;
    }
  }

  .list-table {
    &__heading {
      background: #F8F8F8;
      border: 1px solid #C4C8D6;
      border-radius: 6px 6px 0px 0px;
      padding: to-rem(15px) to-rem(30px);
    }
    &__row {
      border: 1px solid #C4C8D6;
      border-top: none;
      padding: to-rem(0px) to-rem(30px);
    }
    &.ssh {
      .copy {
        opacity: 0;
        font-weight: 400;
        cursor: pointer;
        transition: all 0.7s ease-in;
      }
      .list-table__row:hover {
        .copy {
          opacity: 1;
        }
      }
    }
    .p-r-50 {
      padding-right: 0 !important;

      @include minTablet {
        padding-right: to-rem(50px) !important;
      }
    }
  }
}

.DeactivateAccount {
  p {
    color: #676767;
    font-size: to-rem(18px);
    line-height: 1.6;
    font-weight: 400;
    margin-bottom: to-rem(35px);
  }

  h3 {
    font-size: to-rem(20px);
    font-weight: 400;
    margin-bottom: to-rem(30px);
  }

  label {
    display: flex;
    align-items: center;
    color: #676767;
    font-size: to-rem(18px);
    font-weight: 400;
    margin-bottom: to-rem(40px);

    .checkbox {
      margin-right: to-rem(10px);
      margin-bottom: 0;
    }
  }
}

.Notifications {
  .font-size-18 {
    font-size: 13px;

    @include minTablet {
      font-size: to-rem(18px);
    }
  }
}

.keyPop {
  position: absolute;
  background: #fff;
  top: calc(100% - 1px);
  z-index: 1;
  width: to-rem(340px);
  right: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  display: flex;
  .visible-xs {
    width: 100%;
  }
  @include minTablet {
    display: block;
    width: to-rem(160px);
    left: 50%;
    transform: translateX(-50%);
  }

  .fingerPrint {
    justify-content: flex-end;

    .copy {
      opacity: 1 !important;
      margin-left: to-rem(10px);
    }
  }
  > div {
    text-align: center;
    cursor: pointer;
    margin: to-rem(10px);

    @include minTablet {
      padding: to-rem(15px) 0;
      margin: 0;
    }

    &.editKey {
      color: #8a8a8a;
      font-weight: 400;
      @include minTablet {
        border-bottom: 1px solid #d3d6e2;
      }
    }

    &.deleteKey {
      color: #ff2c00;
      font-weight: 400;
    }
  }

  @include maxMobile {
    &:before {
      position: absolute;
      bottom: 100%;
      right: to-rem(20px);
      left: auto;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent #d3d6e2 transparent;
    }
    &:after {
      position: absolute;
      bottom: 100%;
      right: to-rem(21px);
      left: auto;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

.ProfilePage {
  .Tabs {
    position: relative;
     &__header {
      border-bottom: none;
    }
    p.date {
      position: absolute;
      margin-top: 0;
      top: 0;
      right: 0;
    }
  }
}
