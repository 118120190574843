.LoginPage {
  h2 {
    text-align: center;
    color: #142149;
    font-size: to-rem(41px);
    font-weight: bold;
    margin: 0 0 to-rem(50px);
  }

  .ill {
    img {
      position: absolute;
      display: none;

      @include minTablet {
        display: block;
      }
    }

    &__1 {
      top: 70px;
      @include maxTablet {
        left: calc(100% + 2rem);
      }
    }

    &__2 {
      left: -123px;
      top: -2px;
      @include maxTablet {
        max-width: to-rem(80px);
      }
    }

    &__3 {
      top: 310px;
      left: -130px;
      @include maxTablet {
        max-width: to-rem(130px);
      }
    }

    @include minDesktopSmall {
      &__1 {
        right: -410px;
        top: -20px;
      }

      &__2 {
        left: -200px;
        top: -160px;
      }

      &__3 {
        top: 130px;
        left: -370px;
      }
    }
  }
}
