.flex {
  display: flex;

  &-justify-content {
    &-space-between {
      justify-content: space-between;
      &-important {
        justify-content: space-between !important;
      }
    }
    &-space-around {
      justify-content: space-around;
    }
    &-center {
      justify-content: center;
    }
    &-flex-end {
      justify-content: flex-end;
    }
    &-flex-start {
      justify-content: flex-start;
    }
  }

  &-align-self {
    &-flex-end {
      align-self: flex-end;
    }
  }

  &-column {
    flex-direction: column;
  }

  &-align-items {
    &-center {
      align-items: center;
    }
    &-flex-end {
      align-items: flex-end;
    }
    &-flex-start {
      align-items: flex-start;
    }
  }

  &-grow {
    &-1 {
      flex-grow: 1;
    }
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-direction {
    &-column {
      flex-direction: column;
    }
  }

  &-mobile {
    &-column {
      flex-direction: column;
      @include minTablet {
        flex-direction: row;
      }
    }
    &-justify-content-flex-start {
      justify-content: flex-start;
    }
    &-align-items-flex-start {
      align-items: flex-start;
    }
  }
}

.min-width {
  &-400 {
    min-width: to-rem(400px);
  }
  &-320 {
    min-width: to-rem(320px);
  }
  &-250 {
    min-width: to-rem(250px);
  }
  &-220 {
    min-width: to-rem(220px);
  }
  &-200 {
    min-width: to-rem(200px);
  }
  &-150 {
    min-width: to-rem(150px);
  }
  &-120 {
    min-width: to-rem(120px);
  }
  &-100 {
    min-width: to-rem(100px);
  }
  &-70 {
    min-width: to-rem(70px);
  }
}

.max-width {
  &-70 {
    max-width: to-rem(70px);
  }
  &-85 {
    max-width: to-rem(85px);
  }
  &-100 {
    max-width: to-rem(100px);
  }
  &-120 {
    max-width: to-rem(120px);
  }
  &-150 {
    max-width: to-rem(150px);
  }
  &-170 {
    max-width: to-rem(170px);
  }
  &-222 {
    max-width: to-rem(222px);
  }
  &-250 {
    max-width: to-rem(250px);
  }
  &-350 {
    max-width: to-rem(350px);
  }
  &-400 {
    max-width: to-rem(400px);
  }
  &-450 {
    max-width: to-rem(450px);
  }
  &-500 {
    max-width: to-rem(500px);
  }
  &-550 {
    max-width: to-rem(550px);
  }
  &-600 {
    max-width: to-rem(600px);
  }
  &-650 {
    max-width: to-rem(650px);
  }
  &-700 {
    max-width: to-rem(700px);
  }
  &-800 {
    max-width: to-rem(800px);
  }
  &-850 {
    max-width: to-rem(850px);
  }
  &-900 {
    max-width: to-rem(900px);
  }
  &-960 {
    max-width: to-rem(960px);
  }
  &-1070 {
    max-width: to-rem(1070px);
  }
  &-1240 {
    max-width: to-rem(1240px);
  }
  &-1420 {
    max-width: to-rem(1420px);
  }
  &-1500 {
    max-width: to-rem(1500px);
  }
  &-full-width {
    max-width: 100%;
  }
}

.width {
  &-full-width {
    width: 100%;
  }
  &-half {
    width: 50%;

    &-tablet {
      @include minTablet {
        width: 50%;
      }
    }
  }
}

.height {
  &-full-height {
    height: 100%;
  }

  &-inherit {
    height: inherit;
  }
}

.font-size {
  &-50 {
    font-size: to-rem(50px);
  }
  &-36 {
    font-size: to-rem(36px);
  }
  &-27 {
    font-size: to-rem(27px);
  }
  &-24 {
    font-size: to-rem(24px);
  }
  &-21 {
    font-size: to-rem(21px);
  }
  &-22 {
    font-size: to-rem(22px);
  }
  &-20 {
    font-size: to-rem(20px);
  }
  &-18 {
    font-size: to-rem(18px);
  }
  &-17 {
    font-size: to-rem(17px);
  }
  &-16 {
    font-size: to-rem(16px);
  }
  &-15 {
    font-size: to-rem(15px);
  }
  &-14 {
    font-size: to-rem(14px);
  }
  &-13 {
    font-size: to-rem(13px);
  }
  &-12 {
    font-size: to-rem(12px);
  }
  &-10 {
    font-size: to-rem(10px);
  }
  &-9 {
    font-size: to-rem(9px);
  }
}

.font-style {
  &-italic {
    font-style: italic;
  }
}

.font-weight {
  &-bold {
    font-weight: 700;
    &-important {
      font-weight: 700 !important;
    }
  }
  &-semibold {
    font-weight: 600;
    &-important {
      font-weight: 600 !important;
    }
  }
  &-medium {
    font-weight: 500;
    &-important {
      font-weight: 500 !important;
    }
  }
  &-regular {
    font-weight: 400;
    &-important {
      font-weight: 400 !important;
    }
  }
  &-light {
    font-weight: 300;
    &-important {
      font-weight: 300 !important;
    }
  }
}

.display {
  &-inline-block {
    display: inline-block;
  }
  &-block {
    display: block;
  }
  &-none {
    display: none;
  }
}

.text {
  &-decoration {
    &-none {
      text-decoration: none;
    }
    &-underline {
      text-decoration: underline;
    }
  }
}

.text-align {
  &-right {
    text-align: right;
  }
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;

    &-mobile {
      text-align: center;

      @include minTablet {
        text-align: left;
      }
    }
  }
}

.position {
  &-absolute {
    position: absolute;
  }
  &-relative {
    position: relative;
  }
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.hidden {
  &-xs {
    display: none;

    @include minTablet {
      display: block;

      &-flex {
        display: flex;
      }
    }
  }
  &-sm {
    display: none;

    @media screen and (min-width: 992px) {
      display: block;

      &-flex {
        display: flex;
      }
    }
  }
  &-md {
    display: none;

    @include minDesktopSmall {
      display: block;

      &-flex {
        display: flex;
      }
    }
  }
  &-lg {
    display: none;

    @include minDesktopLarge {
      display: block;

      &-flex {
        display: flex;
      }
    }
  }
}

.visible {
  &-xs {
    display: block;

    &-flex {
      display: flex;
    }

    @include minTablet {
      display: none;
    }
  }
  &-sm {
    display: block;

    &-flex {
      display: flex;
    }

    @media screen and (min-width: 992px) {
      display: none;
    }
  }
  &-md {
    display: block;

    &-flex {
      display: flex;
    }

    @include minDesktopSmall {
      display: none;
    }
  }
}

.margin {
  &-auto {
    margin: auto;
  }
}

.no {
  &-bottom-border {
    border-bottom: none !important;
  }
  &-top-border {
    border-top: none !important;
  }
  &-border {
    border: none !important;
  }
}
.float {
  &-right {
    float: right;
  }
}

.z-index {
  &-1 {
    z-index: 1;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.line-height {
  &-1 {
    &-4 {
      line-height: 1.4;
    }
    &-6 {
      line-height: 1.6;
    }
  }
}

.no-touch-background {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.border {
  &-grey-1 {
    border: 1px solid #c4c8d6;
    border-radius: to-rem(6px);
  }
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

.border-radius-6 {
  border-radius: to-rem(6px);
}

.line_cuted {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(196, 200, 214, 0) 3.82%, #C4C8D6 52.16%, rgba(196, 200, 214, 0) 96.59%);
  padding: 0 !important;
}

.color-transparent {
  color: transparent !important;
}

.width-half {
  width: 100%;
  @include minTablet {
    width: 50%;
  }
}
.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.right {
  &-14 {
    right: to-rem(14px)
  }
}
