.CreateLoadBalancer {
  .ChooseLocation {
    .Card {
      max-width: 25%;
      height: to-rem(87px);
    }
  }

  .ForwardRules {
    .forLayoutPurpose {
      opacity: 0;
      pointer-events: none;
    }

    .rules {
      & .protocol {
        max-width: to-rem(190px);
        margin-right: to-rem(20px);
      }

      & .algorithm {
        max-width: to-rem(190px);
        margin-right: to-rem(42px);
      }

      & .port {
        max-width: to-rem(190px);
        margin-right: to-rem(20px);
      }

      & .deleteRule {
        display: flex;
        justify-content: flex-end;
        max-width: to-rem(110px);
      }
    }
  }

  .ChangeSettings {
    .healthChecks {
      &.row {
        margin: 0 to-rem(-10px);
      }

      >div {
        flex: 1;
      }
    }
  }
}

.Create {
  .rules {
    display: block;
    border-radius: to-rem(4px);

    &__head {
      padding: to-rem(20px) to-rem(15px) to-rem(20px) 0;

      .protocol {
        margin-right: to-rem(33px) !important;
      }

      >div {
        font-size: to-rem(16px);
        font-weight: 600;
        // margin: 0 to-rem(10px);
        flex: 1;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(20px);

      .existingRulesContainer {
        border-radius: to-rem(6px);
        border: 1px solid #d3d6e2;

        .existingRules {
          padding: to-rem(16px) to-rem(20px);

          input select {
            width: 190px;
          }

          >div {
            display: flex;
            flex: 1;
            // margin: to-rem(10px);

            >div {
              margin: to-rem(10px) 0px;
              flex: 1;
            }
          }
        }
      }

    }
  }
}

.InstanceSelectCont {
  padding: to-rem(20px) to-rem(20px);
  background: #F8F8F8;
  border-top: 1px solid #d3d6e2;
  border-radius: 0 0 to-rem(6px) to-rem(6px);

  input {
    padding: to-rem(15px) to-rem(52px);
    border: 1px solid #d3d6e2;
    border-radius: to-rem(6px);
  }
}

.SelectedInsctanceCont {
  display: flex;
  flex-direction: column;
  margin-top: to-rem(20px);
}

.InstanceSelect {
  .Options_list {
    max-width: to-rem(560px);
    padding: to-rem(10px);
    border-radius: to-rem(6px);

    li {
      border-bottom: none;
    }

    .Instance__title-ico {
      width: unset;
    }

    .Instance__status {
      right: unset;
      bottom: unset;
      left: to-rem(7px);
    }
  }

  .Menu_content {
    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 10px 9px;
      border-color: transparent transparent #c4c8d6 transparent;
      position: absolute;
      top: 110%;
      right: 95%;
    }

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 10px 9px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      top: 114%;
      right: 95%;
      z-index: 11;
    }
  }
}

.ForwardRules {
  .forLayoutPurpose {
    opacity: 0;
    pointer-events: none;
  }

  .rules {
    &__create {
      min-width: to-rem(170px);
    }

    &__head {
      >div {
        font-size: to-rem(16px);
        font-weight: 600;
        // margin: 0 to-rem(10px);
        flex: 1;
      }
    }

    & .protocol {
      margin-right: to-rem(20px);
    }

    & .algorithm {
      min-width: to-rem(190px);
      margin-right: to-rem(20px);
    }

    & .port {
      max-width: to-rem(270px);
      margin-right: to-rem(20px);
    }

    & .addVm {
      max-width: to-rem(110px);
    }

    & .deleteRule {
      display: flex;
      justify-content: flex-end;
      max-width: to-rem(110px);
    }
  }
}