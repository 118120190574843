@import './components/common/style';

.CreateInstance {
  h3.font-size-20 {
    font-size: to-rem(27px)
  }
  .PrivateComponent & {
    padding: to-rem(10px);

    @include minTablet {
      padding: to-rem(35px);
    }

    @include minDesktopLarge {
      padding: 0;
    }
  }

  .ChoosePlan {
    .Card {
      img {
        max-width: to-rem(52px);
      }

      &__title {
        color: #444444;
        font-weight: 600;
      }

      &__body {
        cursor: pointer;
      }
    }

    &__planType {
      flex-direction: column-reverse;

      .Card {
        margin-bottom: to-rem(10px);

        &:first-child {
          margin-bottom: 0;
        }

        &__body {
          min-height: to-rem(98px);
          &.disabled {
            opacity: 0.4;
            pointer-events: none;
          }
          &.active {
            &:before {
              position: absolute;
              right: to-rem(12px);
              top: to-rem(12px);
              content: "";
              display: block;
              width: to-rem(18px);
              height: to-rem(18px);
              background: transparent url("../../../assets/images/blue-star.svg") no-repeat;
              background-size: contain;
            }
          }
        }
      }

      @include minTablet {
        flex-direction: row;

        .Card {
          margin-bottom: 0;
        }
      }
    }

    .standard-description {
      .Card__body {
        padding: to-rem(15px) to-rem(20px);

        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 8px 10px 8px;
          border-color: transparent transparent #ffffff transparent;
          position: absolute;
          bottom: calc(100% - 1px);
          left: calc(18% - 30px);
        }

        &:before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 9px 10px 9px;
          border-color: transparent transparent #c4c8d6 transparent;
          position: absolute;
          bottom: 100%;
          left: calc(18% - 31px);
        }
      }

      &--cpuOptimized .Card__body {
        &:after {
          left: calc(50%);
        }
        &:before {
          left: calc(50% - 1px);
        }
      }
      &--memoryOptimized .Card__body {
        &:after {
          right: calc(18% - 30px);
          left: auto;
        }
        &:before {
          right: calc(18% - 31px);
          left: auto;
        }
      }
    }

    .performance {
      &.row {
        margin: 0 -20px 0 auto;

        @include minTablet {
          margin: 0 to-rem(-5px);
          padding: 0;
        }

        .slick-prev {
          left: to-rem(-25px);
        }

        .slick-next {
          right: to-rem(-25px);
        }
      }

      >div {
        .soon {
          position: absolute;
          bottom: to-rem(-10px);
        }
      }
    }

    .settingsIco {
      max-width: to-rem(16px);
    }

    &__slider {
      & .slick-list {
        & .slick-track {
          display: flex;
        }
      }
    }

    .slick-slide {
      .p-l-15 {
        padding-left: 0!important;
      }
      @include minTablet {
        max-width: to-rem(250px);
        padding-left: to-rem(15px)!important;
      }
    }

    .slick-track {
      min-width: 100%;
    }
  }

  .ChooseImage {
    .Tabs {
      &__header {
        border-bottom: 0;
        margin-bottom: to-rem(31px);
      }
    }

    .Distributions {
      margin: 0 -20px 0 auto;

      .flex {
        flex-wrap: wrap;
      }

      .slick-list {
        overflow: visible!important;
      }

      &__info {
        position: absolute!important;
        top: to-rem(30px);
        right: to-rem(30px);
        z-index: 10;
      }


      @include minTablet {
        max-width: 100%;
        margin: auto;
        margin-top: to-rem(-10px);
      }

      .row {
        margin: 0 to-rem(-15px);
        flex-wrap: wrap;
      }

      img {
        max-height: to-rem(40px);
      }

      &__box {
        min-height: to-rem(64px);
        color: #444444;
        font-weight: 600;
        font-size: to-rem(16px);
        padding: to-rem(15px);

        p {
          margin: 0;
          color: #676767;
          font-weight: 400;
          font-size: to-rem(14px);
        }

        .arrow {
          border-color: #676767;
          border-width: 1px;
          width: to-rem(8px);
          height: to-rem(8px);
        }
      }

      &__version {
        color: #444444;
        font-weight: 600;
        font-size: to-rem(14px);
        margin-bottom: to-rem(10px);
        position: absolute;
        background: #fff;
        z-index: 1;
        width: calc(100% + 2px);
        left: -1px;
        border: 1px solid #C4C8D6;
        border-top: none;
        border-radius: 0 0 6px 6px;
        padding: 0 to-rem(10px) to-rem(15px);

        > div {
          padding: to-rem(5px) to-rem(10px);

          &:hover {
            background: #F5F6F7;
            border-radius: 5px;
          }
        }
      }

      .Card {
        .flex {
          flex-wrap: nowrap;
        }
        cursor: pointer;
        &__body {
          align-items: center;
          margin: to-rem(15px);
          border-radius: 6px;


          &:hover {
            .Distributions__version {
              border-color: #0069ff;
            }
          }

          @include minTablet {
            width: to-rem(219px);
          }

          &.active {
            background: #031B4E;
            border-color: #031B4E;
            position: relative;
            &:before {
              position: absolute;
              right: to-rem(15px);
              top: to-rem(15px);
              content: "";
              display: block;
              width: to-rem(24px);
              height: to-rem(24px);
              background: transparent url("../../../assets/images/white-star.svg") no-repeat;
              background-size: contain;
            }
            .Distributions__box, .Distributions__version {
              color: #fff;
              background: #031B4E;
              border-color: #031B4E;
              p {
                color: #fff;
              }
              > div {
                color: #fff;
              }
            }
            .Distributions__version > div:hover {
              background: #7b85a0;
            }
            .arrow {
              border-color: #FFFFFF;
            }
          }
        }

      }

      .Field__content {
        width: 100%;
        max-width: to-rem(667px);
      }

      select {
        max-width: 100%;
        border: none;
        font-size: to-rem(12px);
        color: #676767;
        outline: none;
        background-size: to-rem(10px);
        font: normal normal 600;
      }
    }

    .OneClick {
      > .flex {
        flex-wrap: wrap;
      }

      img {
        min-width: to-rem(47px);
      }

      .arrow-long {
        position: absolute;
        right: to-rem(20px);
      }

      .arrow {
        top: calc(50% - #{to-rem(3px)});
      }

      .Card {
        cursor: pointer;

        @include minTablet {
          max-width: 33.3%;
        }

        &__body {
          height: to-rem(112px);
          padding: to-rem(15px);

          &.active {
            background: #031B4E;
            border-color: #031B4E;
            color: #FFFFFF;
            position: relative;
            &:before {
              position: absolute;
              right: to-rem(12px);
              top: to-rem(12px);
              content: "";
              display: block;
              width: to-rem(18px);
              height: to-rem(18px);
              background: transparent url("../../../assets/images/white-star.svg") no-repeat;
              background-size: contain;
            }
            .OneClick__version {
              color: #fff;
              background: #031B4E;
              border-color: #031B4E;
              p {
                color: #fff;
              }
              > div {
                color: #fff;
              }
            }
            .OneClick__version > div:hover {
              background: #7b85a0;
            }
            .arrow {
              border-color: #FFFFFF;
            }
          }

          &.showAll-button {
            &:hover {
              background: #0069ff;
              color: #fff;

              .arrow {
                border-color: #fff;
              }

              .LongArrow {
                stroke: #fff;
              }
            }
          }
        }
      }

      &__version {
        color: #444444;
        font-weight: 600;
        font-size: to-rem(14px);
        margin-bottom: to-rem(10px);
        position: absolute;
        top: calc(100% - #{to-rem(5px)});
        background: #fff;
        z-index: 1;
        width: calc(100% + 2px);
        left: -1px;
        border: 1px solid #C4C8D6;
        border-top: none;
        border-radius: 0 0 6px 6px;
        padding: 0 to-rem(10px) to-rem(15px) to-rem(65px);

        > div {
          padding: to-rem(5px) to-rem(10px);

          &:hover {
            background: #F5F6F7;
            border-radius: 5px;
          }
        }
      }

      .nav {
        >* {
          flex: 1;
          width: 100%;
          margin: to-rem(10px) 0;

          @include minTablet {
            margin: 0;
          }
        }

        .show-more {
          color: #0069ff;

          @include minTablet {
            max-width: 33.3%;
          }

          .Card {
            max-width: 100%;
          }

          .Card__body {
            box-shadow: 0px 3px 6px #0069ff29;
            border: 1px solid #0069ff;
          }
        }
      }
    }
  }

  .ChooseLocation {
    .Locations {
      margin: 0 -20px 0 auto;

      @include minTablet {
        max-width: 100%;
        margin: auto;
      }

      .Card {
        padding-right: 20px !important;

        @include minTablet {
          padding-right: 0 !important;
        }
      }

      .flex {
        flex-wrap: wrap;

        .Card {
          cursor: pointer;
          margin: 0 to-rem(15px);
          max-width: calc(25% - #{to-rem(30px)});
        }
      }

      img {
        max-width: to-rem(50px);
      }
    }
  }

  .VpcNetwork,
  .RootPassword {
    .bg-blue-1 {
      border-radius: to-rem(3px);
      position: relative;
      margin-top: to-rem(20px);

      @include maxMobile {
        max-width: 100% !important;
      }

      @include minTablet {
        margin-top: 0;
      }

      &:before {
        @include minTablet {
          content: '';
          position: absolute;
          right: 100%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: to-rem(8px) to-rem(10px) to-rem(8px) 0;
          border-color: transparent #0069ff transparent transparent;
        }
      }
    }
  }

  .VpcNetwork {
    .relative {
      position: relative;
    }

    >.flex>div {
      @include minTablet {
        width: 50%;
      }
    }

    .noVpc {
      position: absolute;
      pointer-events: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #676767;

      @include minTablet {
        font-size: to-rem(13px);
        width: to-rem(200px);
      }

      @include minDesktopSmall {
        font-size: to-rem(16px);
        width: auto;
      }
    }

    select {
      @include minTablet {
        font-size: to-rem(13px);
      }

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
  }

  .RootPassword {

    // .bg-blue-1 {
    //   max-width: to-rem(410px);
    // }
    >.flex>div {
      @include minTablet {
        width: 50%;
      }
    }
  }

  .Authentication,
  .BackupWork {
    &--disabled {
      .auth-box {
        opacity: 0.4;
        pointer-events: none;
      }
    }

    .width-half {
      width: 100%;

      @include minTablet {
        width: 50%;
      }
    }

    .ssh-keys {
      border: 1px solid #C4C8D6;
      padding: to-rem(23px) to-rem(30px);
      border-radius: 6px;
      margin-top: to-rem(20px);
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 10px 8px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        bottom: calc(100% - 1px);
        left: to-rem(34px);
      }

      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 9px 10px 9px;
        border-color: transparent transparent #c4c8d6 transparent;
        position: absolute;
        bottom: 100%;
        left: to-rem(33px);
      }

      &.error {
        border-color: #ff2c00;
      }

      .flex {
        flex-wrap: wrap;
      }

      .SshModal .flex {
        flex-wrap: nowrap;
      }

      .button {
        width: to-rem(160px);
        height: to-rem(48px);
        border: none;
        background: #EEEFF3;
        border-radius: 6px;
        font-weight: 600;
        font-size: to-rem(16px);
        color: #676767;
      }
    }

    .sshKeys {
      >label {
        align-items: center;

        @include maxMobile {
          width: 50%;
          margin: 0 !important;
          padding-bottom: to-rem(10px);
        }
      }
    }
  }

  .VpcNetwork {
    .width-half {
      position: relative;
      width: 100%;
      @include minTablet {
        width: 50%;
      }
    }

    .auth-box {
      &.open {
        border-radius: 6px 6px 0 0;
        border-bottom: 1px solid transparent;
      }
    }

    .selectedAdvanced {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      font-size: to-rem(16px);
      color: #444444;

      .ip {
        font-weight: 400;
        color: #676767;
      }

      .name {
        margin-right: to-rem(10px);
      }
    }

    .AdvancedNetworks {
      position: absolute;
      top: calc(100% - #{to-rem(10px)});
      width: calc(100% - #{to-rem(30px)});
      background: #FFFFFF;
      border: 1px solid #0069FF;
      border-radius: 0px 0px 6px 6px ;

      ul {
        padding: 0;

        li {
          list-style: none;
          font-weight: 600;
          font-size: to-rem(16px);
          padding: to-rem(9px) to-rem(14px);
          margin: 0 to-rem(16px);
          color: #444444;
          display: flex;
          justify-content: space-between;

          .ip {
            font-weight: 400;
            color: #676767;
          }

          .name {
            margin-right: to-rem(10px);
          }

          &:hover, &.active {
            background: #F5F6F6;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .Authentication {
    .auth-box {
      @include minTablet {
        .font-size-16 {
          font-size: to-rem(13px);
        }
      }

      @include minDesktopSmall {
        .font-size-16 {
          font-size: to-rem(15px);
        }
      }
    }
  }

  .BackupWork {
    .auth-box {
      @include maxMobile {
        padding: to-rem(20px);
        align-items: flex-start;
        height: auto;

        >* {
          margin-bottom: to-rem(10px);
        }
      }
    }
  }

  .ConcludeAndCreate {
    .instances {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #d3d6e2;
      height: to-rem(58px);
      padding: 0 to-rem(15px);
      border-radius: to-rem(3px);
    }

    &__count {
      width: auto;
      @include minTablet {
        max-width: 33%;
      }
    }

    .Field input {
      font-weight: 400;
    }

    .plus,
    .minus {
      display: flex;
      justify-content: center;
      line-height: 1;
      align-items: center;
      cursor: pointer;
      border: 1px solid #d3d6e2;
      width: to-rem(20px);
      height: to-rem(20px);
      position: relative;
    }

    .minus {
      &:after {
        content: '';
        display: block;
        width: to-rem(12px);
        height: to-rem(2px);
        background: #707070;
      }
    }

    .configuration {
      position: relative;
      display: inline-flex;
      cursor: pointer;

      &-pop {
        position: absolute;
        background: #fff;
        left: 50%;
        transform: translateX(-50%);
        padding: to-rem(15px);
        box-shadow: 0px 5px 8px #0069ff29;
        border: 1px solid #0069ff;
        border-radius: to-rem(3px);
        bottom: 100%;
        min-width: to-rem(185px);
        opacity: 0;
        transition: all 0.6s ease;
        pointer-events: none;
      }

      .location {
        margin-top: to-rem(10px);

        img {
          max-width: to-rem(38px);
        }
      }

      &:hover {
        .configuration-pop {
          opacity: 1;
        }
      }
    }
  }

  .image-box {
    img {
      max-width: to-rem(37px);
    }
  }

  .image-box-container {
    flex-wrap: wrap;

    >div {
      margin-bottom: to-rem(35px);
      width: 100%;

      @include minTablet {
        width: calc(25% - #{to-rem(40px)});
      }
    }

    &.row {
      margin: 0 to-rem(-20px);
    }
  }

  .Tabs {
    &__header {
      border-bottom: 0;
    }
  }

  .AdditionalOptions {
    .flex>.flex {
      align-items: center;
    }

    .textareaWrapper {
      margin-top: to-rem(30px);
      width: 100%;
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 10px 8px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        bottom: calc(100% - 1px);
        left: to-rem(38px);
      }

      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 9px 10px 9px;
        border-color: transparent transparent #c4c8d6 transparent;
        position: absolute;
        bottom: 100%;
        left: to-rem(37px);
      }
    }

    .textarea {
      width: 100%;
      min-height: to-rem(180px);
      resize: vertical;
      border: 1px solid #C4C8D6;
      border-radius: 6px;
      padding: to-rem(20px);

      &::placeholder {
        color: #C4C8D6;
      }
    }

    .disabled {
      color: #C4C8D6;
    }
  }

  .SelectProject {
    > div {
      display: flex;
    }

    .CustomSelect {
      border-radius: 6px 0 0 6px;
    }

    .button {
      border-radius: 0 6px 6px 0;
      width: 100%;
      max-width: to-rem(280px);
    }

    &__input {
      height: to-rem(58px);
      width: 100%;
      display: block;
      border-radius: 6px 0 0 6px;
    }
  }
}

.auth-box {
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #C4C8D6;
  min-height: to-rem(84px);
  border-radius: to-rem(6px);
  margin: to-rem(10px) 0;
  padding: 0 to-rem(10px);

  .Checkbox {
    margin-right: to-rem(21px) !important;
  }

  .text-grey-6 {
    margin-bottom: to-rem(5px);
  }

  @include minTablet {
    padding: 0 to-rem(30px);

    .font-size-15 {
      font-size: to-rem(13px);
    }

    .flex {
      align-items: center;
    }
  }

  @include minDesktopSmall {
    .font-size-15 {
      font-size: to-rem(15px);
    }
  }

  &.active,
  &:hover {
    border: 1px solid #0069ff;
  }
}

.Locations {
  .Card {
    width: 50%;

    @include minTablet {
      width: auto;
    }

    &__body {
      margin: to-rem(15px);
      height: to-rem(88px);
      border-radius: 6px;
      padding-left: to-rem(19px);
      padding-right: to-rem(19px);

      @include minTablet {
        width: to-rem(219px);
      }
      & img {
        width: to-rem(45px);
      }

      &:hover,
      &.active {
        position: relative;
        opacity: 1 !important;
        &:before {
          position: absolute;
          right: to-rem(12px);
          top: to-rem(12px);
          content: "";
          display: block;
          width: to-rem(21px);
          height: to-rem(21px);
          background: transparent url("../../../assets/images/blue-star.svg") no-repeat;
          background-size: contain;
        }
      }

      &.disabled {
        opacity: 0.5;
        background: unset !important;
        pointer-events: none;
      }
    }
  }

  .locations-list {
    display: flex;
    flex-wrap: wrap;
    margin: to-rem(-15px) to-rem(-15px) 0;
  }

  .tabs {
    &-item {
      margin-right: to-rem(20px);
      font-weight: 600;
      color: #999999;
      padding-bottom: to-rem(5px);
      margin-bottom: -1px;
      font-size: to-rem(16px);
      cursor: pointer;

      &--active {
        color: #031b4e;
        border-bottom: 2px solid #0069ff;
      }
    }
  }

}
