.SelectFloatingIp {
  .Menu_content {
    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 10px 9px;
      border-color: transparent transparent #c4c8d6 transparent;
      position: absolute;
      top: 111%;
      right: 95%;
    }

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 10px 9px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      top: 115%;
      right: 95%;
    }

    ul {
      z-index: 0;
      padding: to-rem(20px);
      display: flex;
      flex-direction: column;
      row-gap: to-rem(11px);
      border-radius: to-rem(6px);
      width: to-rem(190px);
      margin-left: to-rem(20px);

      li {
        padding: 0 !important;
        height: auto;
        border-bottom: none;
      }
    }
  }
}

.Checked_Item {
  width: 100%;
  border: 1px solid #C4C8D6;
  padding: to-rem(20px) to-rem(30px);
}

.Select_extraIP {
  .Selected {
    height: to-rem(58px);

    img {
      margin-right: to-rem(10px) !important;
    }
  }

  .Extra_section {
    padding-bottom: to-rem(10px);
  }

  .Options_Container {
    width: to-rem(190px) !important;
    left: to-rem(20px);

    .Option {
      padding-left: to-rem(20px) !important;

      img {
        margin-right: to-rem(10px) !important;
      }
    }

    &:before {
      right: 83%;
      left: unset !important;
    }

    &:after {
      right: 83%;
      left: unset !important;
    }
  }
}

.Select_extraIP_noOptions {
  .Selected {
    height: to-rem(58px);

    img {
      margin-right: to-rem(10px) !important;
    }
  }

  .Options_Container {
    width: to-rem(190px) !important;
    padding: to-rem(15px) 0 !important;
    left: to-rem(20px);

    &:before {
      right: 83%;
      left: unset !important;
    }

    &:after {
      right: 83%;
      bottom: 97% !important;
      left: unset !important;
    }
  }
}
