/*
 * Media query
 */

@mixin mediaQueryMin($minWidth) {
  @media only screen and (min-width: $minWidth) {
    @content;
  }
}

@mixin mediaQueryMax($maxWidth) {
  @media only screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin mediaQueryBetween($minWidth, $maxWidth) {
  @media only screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

/*
 * Min
 */

@mixin minTablet {
  @include mediaQueryMin($minTabletWidth) {
    @content;
  }
}

@mixin minDesktopSmall {
  @include mediaQueryMin($minDesktopSmallWidth) {
    @content;
  }
}

@mixin minDesktopLarge {
  @include mediaQueryMin($minDesktopLargeWidth) {
    @content;
  }
}

@mixin minDesktopWide {
  @include mediaQueryMin($minDesktopWideWidth) {
    @content;
  }
}

@mixin minDesktopExtraWide {
  @include mediaQueryMin($minDesktopExtraWideWidth) {
    @content;
  }
}

/*
 * Max
 */

@mixin maxMobile {
  @include mediaQueryMax($maxMobileWidth) {
    @content;
  }
}

@mixin maxTablet {
  @include mediaQueryMax($maxTabletWidth) {
    @content;
  }
}

@mixin maxDesktopSmall {
  @include mediaQueryMax($maxDesktopSmallWidth) {
    @content;
  }
}

@mixin maxDesktopLarge {
  @include mediaQueryMax($maxDesktopLargeWidth) {
    @content;
  }
}

/*
 * Only
 */

@mixin onlyTablet {
  @include mediaQueryBetween($minTabletWidth, $maxTabletWidth) {
    @content;
  }
}

@mixin onlyDesktopSmall {
  @include mediaQueryBetween($minDesktopSmallWidth, $maxDesktopSmallWidth) {
    @content;
  }
}

@mixin onlyDesktopLarge {
  @include mediaQueryBetween($minDesktopLargeWidth, $maxDesktopLargeWidth) {
    @content;
  }
}

@mixin onlyTabletDesktopSmall {
  @include mediaQueryBetween($minTabletWidth, $maxDesktopSmallWidth) {
    @content;
  }
}

@mixin component() {
  font-family: $font_1;
}
