html {
  font-size: 9px;
  font-family: proxima-nova, sans-serif !important;

  @include minTablet {
    font-size: 10px;
  }

  @include minDesktopSmall {
    font-size: 14px;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:focus {
    outline: none;
  }
}

.Datepicker {
  position: relative;

  img {
    position: absolute;
    left: to-rem(10px);
    top: 50%;
    transform: translateY(-50%);
  }

  .arrow {
    position: absolute;
    right: to-rem(15px);
    top: 48%;
    transform: translateY(-50%) rotate(225deg);
    margin: 0;
    padding: 0;
  }

  input {
    display: flex;
    align-items: center;
    width: 100%;
    height: to-rem(58px);
    padding: to-rem(10px) to-rem(30px) to-rem(10px) to-rem(40px);
    border-radius: to-rem(6px);
    border: 1px solid #C4C8D6;
    outline: none;
    -webkit-appearance: none;
    font-weight: 500;
    color: #444444;
    font-size: to-rem(14px);
    background: transparent !important;

    @include minDesktopSmall {
      font-size: to-rem(16px);
    }

    &[type="checkbox"] {
      height: auto;
    }

    &::placeholder {
      color: #676767;
      font-weight: normal;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
  }
}

  .Field {
  .placeholder {
    position: absolute;
    font-weight: 400;
    font-size: to-rem(16px);
    color: #676767;
    padding: to-rem(7px) to-rem(20px);
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    &--with-value {
      top: to-rem(2px);
      transform: translateY(0);
      font-size: to-rem(14px);
    }
  }

  input {
    display: flex;
    align-items: center;
    width: 100%;
    height: to-rem(58px);
    padding: to-rem(10px) to-rem(20px) to-rem(10px) to-rem(20px);
    border-radius: to-rem(6px);
    border: 1px solid #C4C8D6;
    outline: none;
    -webkit-appearance: none;
    font-weight: 500;
    color: #585757;
    font-size: to-rem(14px);
    background: transparent !important;

    @include minDesktopSmall {
      font-size: to-rem(16px);
    }

    &[type="checkbox"] {
      height: auto;
    }

    &::placeholder {
      color: #676767;
      font-weight: normal;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
  }

  textarea {
    display: flex;
    color: #585757;
    font-weight: normal;
    opacity: 1;
    font-size: to-rem(16px);
    width: 100%;
    padding: to-rem(25px) to-rem(20px) to-rem(10px);
    border-radius: to-rem(3px);
    border: 1px solid #d3d6e2;
    outline: none;

    &[type="checkbox"] {
      height: auto;
    }

    &::placeholder {
      color: #676767;
      font-weight: normal;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #676767;
      font-weight: normal;
      opacity: 1;
      font-size: to-rem(14px);

      @include minDesktopSmall {
        font-size: to-rem(16px);
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
  }

  &--sm {
    &.Select {
      height: to-rem(40px);
    }
    input {
      height: to-rem(40px);
    }
  }

  .error {
    position: absolute;
    color: red;
    margin-top: to-rem(-40px);
    font-weight: 500;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    left: to-rem(22px);
    top: to-rem(10px);
    opacity: 0;
  }

  &--with-error {
    .error {
      margin-top: to-rem(0px);
      opacity: 1;
    }

    input {
      border-color: red;
      padding-top: to-rem(27px);
    }
    select {
      padding-top: to-rem(15px);
    }
  }
  &--with-placeholder {
    input {
      padding-top: to-rem(27px);
    }

    select {
      padding-top: to-rem(15px);
    }
  }

  &--with-ico {
    .placeholder {
      padding-left: to-rem(52px);
    }
    .ico {
      position: absolute;
      left: to-rem(15px);
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      max-width: to-rem(20px);
    }

    .error {
      left: to-rem(52px);
    }

    input {
      padding-left: to-rem(40px);
      @include minDesktopSmall {
        padding-left: to-rem(50px);
      }
    }
  }
}

.title {
  color: #444444;
  font-size: to-rem(38px);
  font-weight: 300;
  letter-spacing: 0px;

  &--sm {
    font-size: to-rem(16px);
  }

  &--with-border {
    color: #031B4E;
    padding-bottom: to-rem(5px);
    border-bottom: 2px solid #0069FF;
    display: inline-block;
    font-weight: 600;
  }
}

.subtitle {
  margin-top: to-rem(20px);
  margin-bottom: to-rem(50px);
  font-size: to-rem(24px);
  text-align: center;

  &-dark {
    color: $subtitle_color_dark;
  }
}

.description {
  font-size: to-rem(20px);
  line-height: 2;
  color: $description_color;
  margin-top: to-rem(30px);
}

.circle {
  background-color: green;
  width: to-rem(20px);
  height: to-rem(20px);
  border-radius: 50%;
}

.column {
  @include minTablet {
    width: 50%;
  }
  display: inline-block;
  padding: to-rem(10px) to-rem(20px);
  vertical-align: top;
}

.button {
  display: flex;
  height: to-rem(52px);
  align-items: center;
  justify-content: center;
  padding: to-rem(10px) to-rem(20px);
  border-radius: to-rem(6px);
  font-size: to-rem(15px);
  cursor: pointer;
  text-decoration: none;
  border: none;
  color: #fff;
  display: inline-block;
  background: #0069FF;
  transition: all 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
  font-weight: 600;
  overflow: hidden;
  position: relative;

  &:hover {
    @include minDesktopSmall {
      background: #1c3aa9;
    }
  }

  &--green {
    background: #17d175;
    &:hover {
      @include minDesktopSmall {
        background: #0cb863;
      }
    }
  }

  &--blueDisabled {
    background: #0069FF;
    opacity: 0.5;
  }

  &--grey {
    background: #eeeff3;
    color: #676767;
    &:hover {
      @include minDesktopSmall {
        background: #d3d6e2;
      }
    }
  }

  &--grey-2 {
    background: #f8f8f8;
    color: #676767;
    border: 1px solid #d3d6e2;

    &:hover {
      @include minDesktopSmall {
        background: #f8f8f8;
        filter: brightness(80%);
      }
    }
  }

  &--full-red {
    background: #ff2c00;
    color: #fff;
    &:hover {
      @include minDesktopSmall {
        background: #e92800;
      }
    }
  }

  &--red {
    background: #fff;
    border: 1px solid #ff2c00;
    height: to-rem(38px);
    color: #ff2c00;
    &:hover {
      @include minDesktopSmall {
        background: #ff2c00;
        color: #fff;
      }
    }
  }

  &--white {
    background: #fff;
    color: #0069ff;
    border: 1px solid #c4c8d6;
    &:hover {
      @include minDesktopSmall {
        background: #0069ff;
        color: #ffffff;
      }
    }
  }

  &--blue {
    background: #0069ff;
    color: #fff;
    &:hover {
      @include minDesktopSmall {
        background: #1c3aa9;
      }
    }
  }

  &--lg {
    padding: to-rem(12.5px) to-rem(32px);
  }

  &--md {
    height: to-rem(42px);
    font-size: to-rem(16px);
  }

  &--sm {
    height: to-rem(40px);
    font-size: to-rem(14px);
  }

  &--s {
    height: to-rem(30px);
    padding: 0 to-rem(20px);
    font-size: to-rem(14px);
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    background: #D3D6E2;
    color: #FFFFFF;;
  }

  &--no-style {
    appearance: none;
    padding: 0;
    border: none;
    background: none;
    display: block;
    font-size: 100%;
    height: auto;

  }
  .circle {
    display: none;
  }
}

select {
  appearance: none;
  position: relative;
  padding-right: to-rem(15px);
  background: transparent url("../images/arrow-down.svg") no-repeat
    calc(100% - 10px) center;
  font-size: to-rem(14px);

  @include minDesktopSmall {
    font-size: to-rem(16px);
  }
}

a {
  color: #0069ff;
  text-decoration: none;

  &.lighter:hover {
    @include minTablet {
      text-decoration: underline;
      filter: brightness(130%);
    }
  }
}

.card {
  display: block;
  margin: auto;
  background: #fff;
  border-radius: to-rem(5px);
  max-width: to-rem(504px);
  padding: to-rem(40px);
}

.container {
  width: 100%;
  display: block;
  max-width: to-rem(1248px);
  margin: auto;
  padding: 0 15px;
}

.Tabs {
  &__header {
    margin-bottom: to-rem(37px);

    @include minTablet {
      max-width: calc(100vw - 80px);
      overflow: visible;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      a {
        display: inline-block;
        margin-right: to-rem(20px);
        font-weight: 600;
        color: #999999;
        padding-bottom: to-rem(5px);
        margin-bottom: -1px;
        font-size: to-rem(16px);

        &.active {
          color: #031b4e;
          border-bottom: 2px solid #0069ff;
        }
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.list-table {
  &__heading {
    display: flex;
    color: #676767;
    font-size: to-rem(16px);
    font-weight: 600;
    padding: to-rem(15px) 0;
    border-bottom: 1px solid #d3d6e2;

    > div {
      flex: 1;
    }
  }
  &__row {
    display: flex;
    border-bottom: 1px solid #d3d6e2;
    color: #676767;
    font-weight: 400;

    > div {
      flex: 1;
      padding: to-rem(16px) 0;
    }
  }
  &--border {
    border: 1px solid #d3d6e2;
    border-radius: to-rem(5px);
    .list-table__heading,
    .list-table__row {
      padding-left: to-rem(15px);
      padding-right: to-rem(15px);
    }
    .list-table__row:last-child {
      border-bottom: none;
    }
  }
}

.popup {
  position: relative;
  border: 1px solid #d3d6e2;
  border-radius: to-rem(6px);

  &:before {
    position: absolute;
    bottom: 100%;
    left: calc(50% - 3px);
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #d3d6e2 transparent;
  }
  &:after {
    position: absolute;
    bottom: 100%;
    left: calc(50% - 2px);
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #fff transparent;
  }
}

.row {
  margin: 0 to-rem(-15px);
}

.badge {
  font-size: to-rem(12px);
  line-height: 1.4;
  color: #fff;
  background: #0069ff;
  border-radius: to-rem(14px);
  padding: to-rem(3px) to-rem(8px);
  font-weight: bold;
}

.react-tag-input {
  border-radius: to-rem(6px);
  border-color:  #C4C8D6;

  input {
    width: auto;
    border: none;
  }

  &__tag {
    background: #f8f8f8;
    font-size: to-rem(15px);
    color: #676767;
    padding: to-rem(5px);

    &__remove {
      background: #f8f8f8;
    }
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  75% {
    transform: scale(40, 40);
    opacity: 0.75;
  }
  100% {
    transform: scale(70, 70);
    opacity: 0;
  }
}

.slick {
  &-arrow {
    display: block;
    width: 31px;
    height: 62px;
    background: #FFFFFF;
    border: 0.775px solid #C4C8D6;
    border-radius: 4.65px;
    z-index: 99;

    &::before {
      content: "";
      display: block;
      width: to-rem(12px);
      height: to-rem(12px);
      border-left: 2px solid #0069ff;
      border-top: 2px solid #0069ff;
      position: absolute;
      top: 50%;
      left: calc(50% - 3px);
    }

    .circle {
      display: none;
    }

    &:hover {
      background: #0069FF;

      &::before {
        border-color: #FFFFFF;
      }
    }
  }
  &-prev {
    left: to-rem(-25px);
    &::before {
      transform: translate(0, -50%) rotate(-45deg);
    }
  }
  &-next {
    right: to-rem(-25px);
    &::before {
      transform: translate(0, -50%) rotate(45deg);
      border-right: 2px solid #0069ff;
      border-left: none;
      left: calc(50% - 9px);
    }
  }

  &-dots {
    bottom: to-rem(-35px);

    li {
      margin: 0;
      button::before {
        font-size: to-rem(12px);
        color: #d3d6e2;
      }

      .circle {
        display: none;
      }

      &.slick-active {
        button::before {
          color: #0069ff;
        }
      }
    }
  }
}

.arrow {
  display: inline-block;
  width: to-rem(10px);
  height: to-rem(10px);
  border-top: to-rem(2px) solid #d3d6e2;
  border-left: to-rem(2px) solid #d3d6e2;
  transform: rotate(135deg);

  &--sm {
    width: to-rem(7px);
    height: to-rem(7px);
  }

  &--blue {
    border-color: #0069ff;
  }

  &--white {
    border-color: #fff;
  }

  &--grey-1 {
    border-color: #676767;
  }

  &--down {
    transform: translateY(-25%) rotate(-135deg);
  }

  &--up {
    transform: translateY(20%) rotate(45deg);
  }
}

.LongArrow {
  fill: none;
  stroke: #0069ff;
  stroke-linecap: round;
  stroke-width: to-rem(2px);
  width: to-rem(12px);
}

label.disabled {
  pointer-events: none;
  color: #676767;
}

.table {
  display: block;
  border-radius: to-rem(4px);
  border: 1px solid #d3d6e2;

  &__head {
    padding: to-rem(15px) to-rem(30px);
    background: #fafafa;
    border-bottom: 1px solid #d3d6e2;

    > div {
      font-size: to-rem(16px);
      font-weight: 600;
      margin: 0 to-rem(10px);
      flex: 1;
    }
  }
  &__body {
    > div {
      padding: to-rem(15px) to-rem(30px);
      border-bottom: 1px solid #d3d6e2;
      display: flex;
      flex: 1;

      > div {
        margin: to-rem(10px);
        flex: 1;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.grey-info {
  background: #eeeff3;
  border-radius: to-rem(3px);
  padding: to-rem(20px);
  color: #676767;
}

.pricing {
  &.row {
    margin: 0 -20px 0 auto;

    @include minTablet {
      margin: 0 to-rem(-15px);
      max-width: inherit;
    }
  }
  &-box {
    width: 100%;

    &__price-per-month {
      display: flex;
      color: #0069FF;

      .currency {
        font-size: to-rem(20px);
        margin-top: to-rem(5px);
      }
      .price {
        font-size: to-rem(70px);
        line-height: 1;
      }
      .month {
        align-self: flex-end;
        position: relative;
        padding-left: to-rem(8px);
        font-size: to-rem(16px);
        font-weight: 400;
        &:before {
          position: absolute;
          left: to-rem(-2px);
          top: 50%;
          transform: translateY(-50%);
          display: inline-block;
          font-weight: 300;
          content: "/";
          font-size: to-rem(16px);
        }
      }
    }
    &__price-per-hour {
      display: flex;
      color: #333333;
      font-weight: 300;
      margin-bottom: to-rem(17px);
      padding-bottom: to-rem(17px);
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: to-rem(170px);
        height: 1px;
        width: 100%;

        background: linear-gradient(90deg, rgba(0, 105, 255, 0) 3.82%, #0069FF 52.16%, rgba(0, 105, 255, 0) 96.59%);
      }
    }

    &__details {
      font-weight: 400;
      font-size: to-rem(15px);
      line-height: to-rem(18px);
      color: #676767;

      > div {
        display: flex;
        align-items: center;
        line-height: to-rem(23px);

        strong {
          margin-right: to-rem(5px);
        }

        &:before {
          content: "";
          display: block;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background: #0069FF;
          margin-right: to-rem(10px);
        }
      }
    }

    .Card {
      padding-right: 12px !important;

      @include minTablet {
        padding-right: 0 !important;
      }
    }

    .Card__body {
      padding: to-rem(15px) to-rem(25px);
      > div {
        width: 100%;
      }
      &:hover,
      &.active {
        background: #031B4E;
        border-color: #031B4E;
        .pricing-box__price-per-month,
        .pricing-box__price-per-hour,
        .pricing-box__details > div {
          color: #FFFFFF;
        }
        position: relative;
        &:before {
          position: absolute;
          right: to-rem(16px);
          top: to-rem(16px);
          content: "";
          display: block;
          width: to-rem(28px);
          height: to-rem(28px);
          background: transparent url("../images/white-star.svg") no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

.react-toast-notifications__container {

}

.react-toast-notifications__toast {
  max-width: 230px;
}

.react-toast-notifications__toast__content {
  display: flex;
  background: #F8F8F8;
  border: 1px solid #0069FF;
  border-radius: 6px;
  font-weight: 500!important;
  font-size: 15px!important;
  line-height: 15px!important;
  color: #676767;
  padding: 10px 25px 10px 15px !important;
  max-width: 270px;
  &::before {
    content: "";
    background: no-repeat url("../images/chat-ico.svg") left center;
    display: inline-block;
    padding-right: 3px;
    margin-right: 5px;
    vertical-align: middle;
    font-weight: 900;
    width: 20px;
    height: 20px;
  }
}

.react-toast-notifications__toast__countdown, .react-toast-notifications__toast__icon-wrapper {
  display: none;
}

.react-toast-notifications__toast__dismiss-button {
  position: absolute;
  right: 0;
  color: #C4C8D6;
}

.horizontal-slider {
  height: to-rem(10px);
  border-radius: 6px;
  background: #C4C8D6;

  .example-track-0 {
    height: to-rem(10px);
    border-radius: 6px;
    background: #0069FF;
  }

  .example-thumb {
    width: to-rem(20px);
    height: to-rem(20px);
    background: #0069FF;
    border: 3.5px solid #FFFFFF;
    border-radius: 50%;
    font-size: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.pointer-events {
  &-none {
    pointer-events: none;
  }
}

textarea, input {
  color: #585757;
  font-size: to-rem(16px);
  font-weight: normal;
  font-family: proxima-nova, sans-serif;
}

.input--with-tooltip {
  position: relative;
  .tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 0 to-rem(8px);
    background: #031B4E;
    right: to-rem(10px);
    min-width: to-rem(84px);
    height: to-rem(30px);
    font-weight: 600;
    font-size: to-rem(13px);
    text-align: center;
    color: #FFFFFF;
    border-radius: 6px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.Loader_grey {
  width: to-rem(20px) !important;
  height: to-rem(20px) !important;
  border: 2px solid #999999 !important;
}

.grey-out {
  opacity: 0.4;
  pointer-events: none;
}

.menu-item {
  .arrow {
    pointer-events: none;
  }
}
