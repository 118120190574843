.Instance {
  &__details {
    // margin-top: to-rem(25px);
    // margin-bottom: to-rem(25px);
    padding: to-rem(20px) 0;
    // border-top: 1px solid #c4c8d6;
    // border-bottom: 1px solid #c4c8d6;

    span {
      margin-right: to-rem(5px);
    }
  }

  &__title-ico {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-right: to-rem(10px);

    img {
      max-width: to-rem(42px);
    }
  }

  .icon--with-border {
    border: 1px solid #c4c8d6;
    width: to-rem(40px);
    height: to-rem(33px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: to-rem(3px);
    cursor: pointer;

    img {
      width: to-rem(15px);
    }
  }

  .custom-tooltip {
    background: #fff;
    padding: to-rem(23px) to-rem(29px) to-rem(27px) to-rem(24px);
    box-shadow: 0px 3px 6px #4285f41f;
    border-radius: 2px;
    width: to-rem(230px);
    border-radius: to-rem(6px);

    .content_contaienr {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(11px);
    }

    .line {
      display: flex;
      justify-content: space-between;
    }
  }

  &__status {
    width: to-rem(12px);
    height: to-rem(12px);
    border: to-rem(2px) solid #ffffff;
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    right: to-rem(7px);
    bottom: to-rem(7px);

    &--active {
      background: #17d175;
    }

    &--inactive {
      background: #ff2c00;
    }
  }

  .menu_tab_container {
    display: flex;
    column-gap: to-rem(30px);

    .menu {
      border-radius: to-rem(6px);
      border: 1px solid #C4C8D6;
      height: fit-content;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        padding: to-rem(18px);
        display: flex;
        flex-direction: column;
        row-gap: to-rem(2px);

        li {
          width: to-rem(165px);
          padding: to-rem(12px) to-rem(14px);

          a {
            display: flex;
            align-items: center;
          }
        }

        .active {
          border-radius: to-rem(5px);
          background: #F5F6F7;
          color: #0069ff !important;
        }
      }
    }
  }

  .loading {
    position: absolute;
    margin-left: to-rem(-60px);
    margin-bottom: to-rem(-10px);
  }

  .settings-ico {
    background: #0069ff;
    padding: to-rem(2px);
    border-radius: 50%;
  }

  .Access_tab {
    row-gap: to-rem(30px);
  }

  .Graphs {
    position: relative;

    .refresh_icon {
      border: 1px solid #C4C8D6;
      border-radius: to-rem(6px);
      width: to-rem(34px);
      height: to-rem(34px);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: to-rem(10px);
      cursor: pointer;
    }

    .Charts_conatiner {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(34px);
      margin-bottom: 100px;

      .Chart_container {
        border: 1px solid #C4C8D6;
        border-radius: 8px;
        padding: 33px 28px;
        padding-bottom: 100px;
      }

      .Chart_title {
        font-size: to-rem(20px);
        color: #444444;
        font-weight: 600;
        margin-bottom: to-rem(30px);
      }

      .Legend {
        width: to-rem(12px);
        height: to-rem(12px);
        border: 2px solid #0069FF;
        border-radius: to-rem(50px);
      }

      tspan {
        color: #C4C8D6;
        font-size: 17px;
        font-weight: 400;
      }

      .recharts-cartesian-axis-tick {
        color: #C4C8D6;
        font-size: 17px;
        font-weight: 400;
      }

      .recharts-cartesian-axis-line {
        display: none;
      }

      .recharts-cartesian-axis-tick-line {
        display: none;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color: #ffffff, $alpha: 0.8);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h3 {
      color: #031b4e;
      font-weight: 600;
      font-style: to-rem(14px);
    }

    .pies {
      .recharts-wrapper {
        margin-top: to-rem(20px);
        transform: rotate(-90deg);
      }

      .flex-grow-1 {
        width: 33.3%;
      }
    }

    .width-half {
      width: 95%;
      margin-bottom: to-rem(50px);

      @include minDesktopSmall {
        width: 50%;
        margin-bottom: 0;
      }
    }

    .flex.row {
      flex-direction: column;
      margin-bottom: 0 !important;

      @include minDesktopSmall {
        flex-direction: row;
        margin-bottom: to-rem(50px) !important;
      }
    }

    .period {
      height: to-rem(33px);

      select {
        font-size: to-rem(15px);
        color: #676767;
        margin-right: to-rem(10px);
      }
    }
  }

  .Networking {
    .distance {
      min-width: to-rem(240px);
    }

    .list_container {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(11px);

      .list_item {
        width: to-rem(170px);
      }

      .source_items_container {
        display: flex;
        column-gap: to-rem(11px);

        .source_item {
          background: #F8F8F8;
          border: 1px solid #c4c8d6;
          border-radius: to-rem(6px);
          padding: to-rem(8px) to-rem(16px);
          font-size: to-rem(15px);
          display: flex;
          align-items: center;
          column-gap: to-rem(8px);
        }
      }
    }
  }

  .Tags {
    .button {
      min-width: to-rem(180px);
      height: to-rem(52px);
      font-size: to-rem(15px);
      font-weight: 600;
    }
  }

  .Resize {
    .Tabs__header ul li {
      a {
        display: flex;
        min-height: to-rem(40px);
        color: #999999;
        padding-bottom: to-rem(6px);
      }

      .active {
        color: #031B4E;
      }
    }

    .list-table__heading {
      border: none;
    }

    .list-table__body {
      display: flex;
      flex-direction: column;
      row-gap: to-rem(11px);
    }

    .list-table__row {
      border: 1px solid #C4C8D6;
      border-radius: to-rem(6px);

      >div {
        padding: 1.14286rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &.active {
        background: #F5F6F6;
      }
    }

    .resize-button {
      height: to-rem(58px);
      font-size: to-rem(18px);
    }
  }
}