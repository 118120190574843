.Tickets {
  &__content {
    >.flex {
      justify-content: space-between;
      flex-wrap: wrap;
      border: 1px solid #d3d6e2;
      border-radius: to-rem(6px);
      padding: to-rem(22px) to-rem(20px);

      &:hover {
        border-color: #0069ff;
        background: #F5F6F6;
        cursor: pointer;
      }

      .markAsRead {
        color: #0069ff;
        font-style: italic;
        font-weight: 300;
      }

      >div {
        font-size: to-rem(15px);
        text-align: left;
        color: #8a8a8a;

        &.text-blue-1 {
          color: #0069ff;
        }

        img {
          max-width: to-rem(26px);
          margin-right: to-rem(7px);
        }

        flex: 1 1;
      }
    }

    .status {
      display: flex;
      align-items: center;
      position: relative;
      bottom: 0;

      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: to-rem(7px);
        height: to-rem(7px);
        background: #c4c8d6;
      }

      &.new, &.opened {
        &::after {
          background: #17d175;
        }
      }

      &.pending {
        &::after {
          background: #FFA511;
        }
      }
    }

    .status-text {
      &.opened {
        color: #17d175;
      }

      &.closed {
        color: #999999;
      }

      &.pending {
        color: #FFA511;
      }
    }
  }

  .button-new {
    border-radius: to-rem(6px);
  }

  .list-table {
    .sender {
      max-width: to-rem(140px);
    }

    .sender-header {
      margin-right: to-rem(20px);
    }

    .status {
      max-width: to-rem(150px) !important;
      cursor: pointer;
    }

    .status-line {
      max-width: to-rem(150px);
    }

    .departament {
      max-width: to-rem(190px);
    }

    .lastreply {
      max-width: to-rem(110px);
      cursor: pointer;
    }

    .lastreply-header {
      max-width: to-rem(130px);
      cursor: pointer;
    }

    .date {
      max-width: to-rem(110px);
      cursor: pointer;
    }
  }

  .list-table__heading>div {
    flex: auto;
  }

  .list-table__heading {
    border: none;
    font-weight: 600;
    font-size: to-rem(12px);
    line-height: to-rem(15px);
    color: #444444;
    text-transform: uppercase;

    & .status {
      position: relative;
      max-width: to-rem(80px);

      &::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: #444444 transparent transparent transparent;
        margin-left: to-rem(4px);
        transition: all 0.2s ease;
      }

      &--descending {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    & .date {
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: #444444 transparent transparent transparent;
        margin-left: to-rem(4px);
        transition: all 0.2s ease;
      }

      &--descending {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    & .lastreply {
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: #444444 transparent transparent transparent;
        margin-left: to-rem(4px);
        transition: all 0.2s ease;
      }

      &--descending {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .Tickets__content {
    display: flex;
    flex-direction: column;
    row-gap: to-rem(11px);
  }

  &_empty {
    p {
      max-width: to-rem(650px);
    }

    img {
      margin-top: to-rem(50px);
      max-width: to-rem(270px);

      @include minTablet {
        margin-top: 0;
      }
    }
  }

  .Replay {
    padding: to-rem(25px) 0;

    .text-area {
      border-radius: to-rem(6px);
    }

    .id {
      min-width: to-rem(140px);
      width: to-rem(140px);
    }

    .closeTicket {
      min-width: to-rem(180px);
      width: to-rem(180px);
    }

    &__content {
      width: 100%;
    }

    .buttons-container {
      display: flex;
      column-gap: to-rem(25px);
    }

    .button-content {
      display: flex;
      align-items: center;
      column-gap: to-rem(8px);
      border-radius: to-rem(6px);
    }

    .submit-button {
      min-width: to-rem(180px);
      width: to-rem(180px);
    }

    .message {
      border: 1px solid #c4c8d6;
      border-radius: to-rem(6px);
      width: 100%;
      margin-bottom: to-rem(25px);
      color: #676767;
      font-size: to-rem(16px);
      padding: to-rem(24px) to-rem(25px) to-rem(35px);

      &__support {
        .message__title {
          >.flex {
            order: 2;
          }
        }
      }
    }
  }

  textarea {
    padding: to-rem(15px);
    border: 1px solid #c4c8d6;
    height: to-rem(150px);
    width: 100%;
  }
}

.NewTicket {
  .container > div{
    @include minDesktopLarge() {
      max-width: calc(100% - #{to-rem(275px)});
    }
  }

  .title {
    color: #0069ff;
  }

  .departament {
    z-index: 2;

    .description-container {
      position: relative;
      flex-grow: 1;

      p {
        background: #FFFFFF;
        border: 1px solid #C4C8D6;
        border-radius: 6px;
        padding: to-rem(12px) to-rem(20px);
        margin: 0;
        height: 100%;
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
      }

      p:before {
        content: "";
        margin: auto;
        position: absolute;
        display: block;
        left: -4px;
        // bottom: calc(100% - 56px);
        top: 43%;
        width: 7px;
        height: 7px;
        transform: rotate(45deg);
        border: 1px solid;
        border-color: transparent transparent #ccc #ccc;
        background-color: white;
      }
    }

    select {
      color: #676767;
      font-weight: 600;
    }
  }

  .subject-container {
    column-gap: to-rem(20px);
  }

  .buttom-file-upload {
    min-width: to-rem(180px);

    img {
      margin-right: to-rem(8px);
    }
  }

  .blue-text-select {
    select {
      color: #0069ff;
    }
  }

  .related {
    select {
      font-weight: 600;
      padding-left: to-rem(60px);
    }

    .Field__content {
      img {
        position: absolute;
        left: to-rem(20px);
        top: calc(50% - 12px);
      }
    }

    .Select__options {
      li {
        color: #0069ff;
        font-weight: 600;
        font-size: to-rem(16px);
        padding: to-rem(15px) to-rem(20px);
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .Tickets {
    .Replay {
      width: 90vw;

      &__wrapper {
        display: grid;
      }

      .id {
        display: none;
      }
    }
  }
}

.Empty_tickets {
  margin-top: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: to-rem(30px);

  img{
    margin-bottom: to-rem(10px);
    width: to-rem(153px);
    height: to-rem(137px);
  }
  p{
    max-width: to-rem(641px);
    text-align: center;
    font-weight: 400;
    font-size: to-rem(16px);
    line-height: to-rem(27px);
  }
}
