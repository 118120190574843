.App {
  overflow: hidden;
}
.Layout {
  &.Login, &.Register {
    .Header__container {
      position: relative;
      z-index: 2;
    }
    .Layout__main {
      position: relative;
      z-index: 1;
    }
  }
  &__right {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    @include minTablet {
      padding-left: to-rem(85px);
    }
  }

  &__main {
    flex-grow: 1;
  }

  &--extended {
    .Layout__right {
      @include minTablet {
        padding-left: to-rem(214px);
      }
    }
  }

  &.ForgotPassword,
  &.ResetPassword,
  &.Login,
  &.Register {
    .Layout__right {
      padding-left: 0;
    }
    .Layout__main {
      padding-left: 0;
      z-index: 3;
    }
  }
}
