.Select_Container {
    position: relative;
    min-width: to-rem(140px);
    height: to-rem(33px);
    font-size: to-rem(15px);
    color: #676767;

    .Selected {
        width: 100%;
        border: 1px solid #C4C8D6;
        border-radius: to-rem(6px);
        padding: to-rem(8px) to-rem(10px) to-rem(8px) to-rem(13px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .Options_Container {
        width: 100%;
        margin-top: to-rem(12px);
        border: 1px solid #C4C8D6;
        border-radius: to-rem(6px);
        background: rgba(255, 255, 255, 1);
        padding: to-rem(7px) 0;
        z-index: 5;

        &:before {
            z-index: 10;
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 7px;
            border-color: transparent transparent #c4c8d6 transparent;
            position: absolute;
            bottom: 100%;
            left: 83%;
          }
      
          &:after {
            z-index: 10;
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 7px;
            border-color: transparent transparent #ffffff transparent;
            position: absolute;
            bottom: 99%;
            left: 83%;
          }

        .Option {
            padding: to-rem(8px) to-rem(10px) to-rem(8px) to-rem(13px);
            cursor: pointer;
            display: flex;
            align-items: center;
        }
    }
}
