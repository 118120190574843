$spaceamounts: (
  -30,
  -20,
  -10,
  -5,
  0,
  5,
  6,
  7,
  8,
  10,
  12,
  14,
  15,
  17,
  20,
  25,
  30,
  35,
  38,
  40,
  45,
  50,
  60,
  70,
  75,
  80,
  100,
  180
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space/14}rem !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space/14}rem !important;
    }
  }
}
