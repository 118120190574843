.Switch {
  cursor: pointer;
  position: relative;
  height: to-em(22px);
  width: to-em(57px);
  background: #e1e4ec;
  padding: to-em(4px);
  border-radius: to-em(14px);
  transition: all 0.2s ease-in-out;
  color: #8a8a8a;
  text-transform: uppercase;
  font-size: to-em(12px);
  line-height: to-em(22px);
  font-weight: 400;
  display: flex;
  align-items: center;

  .circle {
    position: absolute;
    background: #fff;
    left: to-em(4px);
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(138, 138, 138, 0.95);
    -moz-box-shadow: 0px 0px 5px 0px rgba(138, 138, 138, 0.95);
    box-shadow: 0px 0px 5px 0px rgba(138, 138, 138, 0.95);
    width: to-em(14px);
    height: to-em(14px);
  }

  .text {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
    margin-left: to-em(22px);
    transition: all 0.2s ease-in-out;
    padding-top: to-em(1px);
    @include minTablet {
      padding-top: 0;
    }
  }

  &--active {
    background: #17d175;
    color: #fff;

    .text {
      margin-left: 0;
    }

    .circle {
      left: calc(100% - #{to-em(3px)});
      transform: translate(-100%, -50%);
    }
  }

  &--square {
    width: to-rem(66px);
    height: to-rem(33px);
    border-radius: 3px;

    .circle {
      width: to-rem(19px);
      height: to-rem(19px);
      background: #ffffff;
      border-radius: 3px;
      left: to-rem(7px);
      box-shadow: none;
    }

    .text {
      font-size: to-rem(15px);
      margin-left: to-rem(27px);
      padding-top: to-rem(2px);
    }

    &.Switch--active {
      background: #0069ff;
      .circle {
        left: calc(100% - #{to-em(7px)});
      }
      .text {
        font-size: to-rem(15px);
        margin-left: to-rem(7px);
      }
    }
  }
}
