.loader {
  width: to-rem(30px);
  height: to-rem(30px);
  border: to-rem(5px) solid #0069FF;
  border-bottom-color: transparent!important;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &--small {
    width: to-rem(20px);
    height: to-rem(20px);
    border: to-rem(2px) solid #0069FF;
  }

  &--extra-small {
    width: to-rem(12px);
    height: to-rem(12px);
    border: to-rem(2px) solid #0069FF;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
