.Instace_Select__listing {
  .Instance__title-ico {
    position: unset !important;
    width: to-rem(110px) !important;
    justify-content: flex-start;
    display: flex;
    column-gap: to-rem(15px);
  }

  .Instance__status {
    position: unset;
  }
}

.Instance_Select {
  .Menu_content {
    &:before {
      z-index: 10;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 10px 9px;
      border-color: transparent transparent #c4c8d6 transparent;
      position: absolute;
      top: 111%;
      right: 94%;
    }

    &:after {
      z-index: 10;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 10px 9px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      top: 115%;
      right: 94%;
    }
  }

  ul {
    border-radius: to-rem(6px);
    padding: 0 to-rem(21px);

    li {
      border-bottom: unset;
    }
  }
}
