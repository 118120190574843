.FileUpload {
  display: flex;
  height: to-rem(58px);
  align-items: center;
  justify-content: center;
  padding: to-rem(10px) to-rem(20px);
  font-weight: 600;
  color: #676767;
  border: 1px solid #0069ff;
  border-radius: 3px;
  position: relative;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.uploaded-container {
  row-gap: to-rem(15px);
  margin-top: to-rem(15px);

  .FileUpload {
    border-radius: to-rem(6px);
    max-width: to-rem(215px);
  }

  .truncate {
    width: to-rem(100px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 to-rem(8px);
    color: #0069FF;
  }
}