.Card {
  border-radius: to-rem(3px);

  &__title {
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border: 1px solid #d3d6e2;
    border-bottom: none;
    height: to-rem(37px);
    padding: 0 to-rem(10px);
  }

  &__body {
    display: flex;
    background: #fff;
    padding: to-rem(10px);
    border: 1px solid #C4C8D6;
    border-radius: to-rem(6px);
    position: relative;

    &.active,
    &:hover {
      border: 1px solid #0069ff;
    }
  }

  &__body_disabled {
    border: 1px solid #d3d6e2 !important;
    pointer-events: none !important;
  }

  &--title-bottom {
    .Card {
      &__title {
        border-bottom: 1px solid #d3d6e2;
        border-top: none;
        border-radius: 0 0 to-rem(3px) to-rem(3px);
      }
    }
  }

  &--title-top {
    .Card {
      &__title {
        border-radius: to-rem(3px) to-rem(3px) 0 0;
      }

      &__body {
        border-radius: 0 0 to-rem(3px) to-rem(3px);
      }
    }
  }

  &--full-border {

    &.active,
    &:hover {

      .Card__title,
      .Card__body {
        border-color: #0069ff;
      }
    }
  }
}
